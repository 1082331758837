import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['id', 'name', 'option', 'container']

  connect() {
    this.containerTarget.hidden = true
    this.url = this.element.dataset.url;
    this.originUrl = this.url;
    this.useFetch = Boolean(this.element.dataset.useFetch)
    this.useReplace = Boolean(this.element.dataset.useReplace)
    this.replaceUrl = Boolean(this.element.dataset.replaceUrl)
    this.interval = undefined;

    const id = this.idTarget.value;
    const name = this.nameTarget.value;

    if (id && name) this.fetchOptions(name);
  }

  replace(event) {
    const { value } = event.target;

    this.url = this.originUrl.replace(this.replaceUrl, value)
  }

  onChange(event) {
    const { value } = event.target;
    const search = value.toLowerCase()

    if (this.interval) clearTimeout(this.interval);

    this.interval = setTimeout(async () => {
      if (this.useFetch) await this.fetchOptions(search)
      else this.filterOptionByName(search)
      this.interval = undefined;
    }, 500)
  }

  blur() {
    setTimeout(() => this.containerTarget.hidden = true, 500)
  }

  focus() {
    this.containerTarget.hidden = false;
  }

  onClick(event) {
    const { id, name } = event.target.dataset;

    this.containerTarget.hidden = true
    this.idTarget.value = id
    this.nameTarget.value = name
  }

  filterOptionByName(search) {
    this.optionTargets.forEach(option => {
      const { name } = option.dataset;
      name = name.toLowerCase();
      option.hidden = !name.match(search)
    })
  }

  async fetchOptions(value) {
    const search = new URLSearchParams({
      search: value
    })
    
    const response = await fetch(`${this.url}?${search}`);

    if (response.ok) {
      const data = await response.json();

      return this._addOptins(data);
    }

    this._addOptins([])
  }

  _addOptins(options) {
    const optionsHTML = options.map(element => {
      return `<li
        data-id="${element.id}"
        data-name="${element.name}"
        data-action="click->auto-complete-selection#onClick"
      >
        ${element.name}
      </li>`
    })

    this.containerTarget.innerHTML = optionsHTML.join('\n')
  }
}