import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['index'];

  connect() {
    if (this.hasIndexTarget) {
      this.indexTargets.forEach((element, index, self) => {
        element.innerText = `${this.formatNumber(index + 1)}/${this.formatNumber(self.length)}`;
      });
    }
  }

  evaluation(event) {
    this.pdf(this.element.dataset.url);
  }

  evaluationPartial(event) {
    this.pdf(this.element.dataset.url + '?partial=true')
  }

  pdf(url) {
    const pdf = window.open(url, '', 'height=700,width=850');
    pdf.document.close();
    pdf.addEventListener('afterprint', pdf.close);
  }

  formatNumber(number) {
    return String(number).padStart(2, '0');
  }
}