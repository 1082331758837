import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "tree", "member", "link", "profile", "list", "name", "image" ]

  async connect() {
    this.showProfile = true;
    try {
      const response = await fetch(this.element.dataset.value + '.json');
      const data = await response.json();
      const tree = data.map(element => {
        return this.mount(element);
      });
      
      this.treeTarget.innerHTML = tree;
      this.update();
    } catch (e) {
      console.error(e)
    }
  }

  update(){
    this.profileTarget.hidden = this.showProfile;
    this.showProfile = !this.showProfile;
    this.listTarget.hidden = this.showProfile;
    this.treeTarget.hidden = this.showProfile;
  }

  async profile(event) {
    const { url } = event.currentTarget.dataset;
    const response = await fetch(url);
    const data = await response.text();
    const body = this.profileTarget.querySelector('.body');
    body.innerHTML = data;
    const code = body.querySelector('#show-profile').innerHTML;
    body.innerHTML = code;
    body.querySelector('.buttons').remove();
    this.update();
  }

  filter(event) {
    const { value, name, image } = event.currentTarget.dataset;

    this.nameTarget.innerHTML = name;
    if (image !== 'null')
      this.imageTarget.innerHTML = `<img alt="${name}" class="logo" src="${image}">`
    this.imageTarget.hidden = image === 'null'

    this.memberTargets.forEach(element => {
      const match = element.dataset.value.match(value)
      element.hidden = !match
    });

    this.linkTargets.forEach(element => {
      element.classList.toggle('showing', element.dataset.value === value)
    });
  }

  mount(item) {
    const isCompany = !!item.id.match('company');
    if (item.submenu?.length) {
      return  `
        <a class="btn node text-left m-0 p-1 mt-2 w-100 ${isCompany && 'showing'}" data-toggle="collapse"
          href="#${item.id}" role="button" aria-expanded="${isCompany}" aria-controls="${item.id}"
          data-value="${item.id}" data-organizations-target="link" data-action="organizations#filter"
          data-name="${item.name}" data-image="${item.image}"
        >
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="name">
              <i class="${item.icon}"></i>
              ${item.name}
            </div>
            <i class="icon fas fa-arrow-right"></i>
          </div>
        </a>
        <div class="collapse ${isCompany && 'show'}" id="${item.id}">
          <div class="d-flex flex-column m-0 ml-3">
            ${item.submenu.map(element => this.mount(element)).reduce((a, b) => a + b, '')}
          </div>
        </div>
      `
    } else {
      return `
      <a class="btn node text-left m-0 p-1 mt-2 w-100" data-organizations-target="link"
        data-value="${item.id}" data-action="organizations#filter"
        data-name="${item.name}" data-image="${item.image}"
      >
        <i class="${item.icon}"></i>
        ${item.name}
      </a>
      `
    }
  }
}