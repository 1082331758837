import { Controller } from "@hotwired/stimulus"
import * as d3 from 'd3'

export default class extends Controller {
  initialize() {
    this.alfa = Number(this.element.dataset.alfa);
    this.matrix = JSON.parse(this.element.dataset.matrix);
    this.labels = JSON.parse(this.element.dataset.labels);
    this.axisX = JSON.parse(this.element.dataset.x);
    this.axisY = JSON.parse(this.element.dataset.y);
    this.circleSize = 6;
    this.minCircleSize = 4;
    this.schemaColors = [
      '#000000',
      ...d3.schemeCategory10,
      ...d3.schemeSet3,
      ...d3.schemePaired,
    ]

    this.createNodes();
    this.createLinks();
    this.createGraph();
  }

  disconnect() {
    this.element.innerHTML = '';
  }

  createNodes() {
    this.nodes = this.labels.map((label, index) => {
      return {
        id: index + 1,
        label,
        x: this.axisX[index],
        y: -this.axisY[index]
      }
    });
  }

  createLinks() {
    this.links = [];
    this.types = ['arrow'];
    this.matrix.forEach((row, source) => {
      row.forEach((column, target) => {
        if(column >= this.alfa && target !== source){
          const type = `type-${this.nodes[source].id}`;
          const existType = this.types.find(currentType => currentType === type)
          if(!existType) this.types.push(type);
          this.links.push({
            source: this.nodes[source].id,
            target: this.nodes[target].id,
            type
          })
        }
      })
    });
  }

  createGraph() {
    const width = this.element.clientWidth * 0.7;
    const height = this.element.dataset.height || this.element.clientHeight;
    const padding = 10;

    const margin = {
      top: padding * 6,
      left: padding * 5,
    }

    const chart_width = width - margin.left;
    const chart_height = height - margin.top;

    this.minX = d3.min(this.nodes, node => node.x);
    this.maxX = d3.max(this.nodes, node => node.x);
    this.maxY = d3.max(this.nodes, node => node.y);
    this.minY = d3.min(this.nodes, node => node.y);
    
    if (this.minX > 0) this.minX = 0;

    this.includeLines()

    this.svg = null;


    const zoomPan = ({ transform }) => {
      this.svg.attr('transform', transform);
    };

    const zoom = d3
      .zoom()
      .extent([
        [0, 0],
        [chart_width, chart_height],
      ])
      .scaleExtent([1, 4])
      .on('zoom', event => zoomPan(event));

    const scaleX = d3.scaleLinear()
      .range([margin.left, chart_width])
      .domain([this.minX, this.maxX])
    const scaleY = d3.scaleLinear()
      .range([margin.top, chart_height])
      .domain([this.minY, this.maxY])

    const simulation = d3.forceSimulation(this.nodes)
      .force('collide', d3.forceCollide().radius(padding * 2))
      .force('xAxis', d3.forceX(node => scaleX(node.x)).strength(1))
      .force('yAxis', d3.forceY(node => scaleY(node.y)).strength(1))
      .force("charge", d3.forceManyBody().distanceMin([padding]).distanceMax([padding * 4]).strength(1))
      .force("link", d3.forceLink(this.links).id(node => node.id).strength([0]))
      .alpha(1)
      

    const svg = d3.select(this.element)
      .style('overflow', 'hidden')
      .append('svg')
      .attr("viewBox", [0, 0, width, height])
      .style("font", "5px sans-serif")
      .call(zoom)

    this.svg = svg;

    svg.append("defs")
      .attr('class', 'arrows')
      .selectAll("marker")
      .data(this.types)
      .join("marker")
        .attr("id", d => `arrow-${d}`)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", d => d === 'arrow' ? 0 : 25)
        .attr("refY", d => d === 'arrow' ? 0 : -0.5)
        .attr("markerWidth", 6)
        .attr("markerHeight", 6)
        .attr("orient", "auto")
      .append("path")
        .attr("fill", this.color.bind(this))
        .attr("d", "M0,-5L10,0L0,5");

    this.linkElements = svg.append("g")
      .attr('class', 'links')
      .attr("fill", "none")
      .attr("stroke-width", 1)
    .selectAll("path")
    .data(this.links)
    .join("path")
      .attr("stroke", d => this.color(d.type))
      .attr("marker-end", d => `url(${new URL(`#arrow-${d.type}`, location)})`);

    const nodes = svg.append("g")
      .attr('class', 'nodes')
      .attr("fill", "currentColor")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
    .selectAll("g")
    .data(this.nodes)
    .join("g")

    const circles = nodes.append("circle")
      .attr("stroke", "white")
      .attr("stroke-width", this.stokeWidth.bind(this))
      .attr("r", this.circleWidth.bind(this))
      .attr("fill", d => this.color(`type-${d.id}`))
      .attr('display', d => d.isLine ? 'none' : '')

    nodes.append("text")
      .attr("x", d => d?.axisLabel?.x || 15)
      .attr("y", d => d?.axisLabel?.y || "0.31em")
      .text(d => d.isLine ? d.label : d.id)
      .style('font-size', '8px')
    .clone(true).lower()
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-width", 3);

    const axisInfo = nodes.append("text")
      .attr("x", 0)
      .attr("y", "2em")
      .text(d => {
        if (d.isLine) 
          return d.label
        return `${d.label} - (${Number(d.x).toFixed(2)}, ${(-Number(d.y)).toFixed(2)})`
      })
      .style('font-size', '8px')
      .style('display', 'none')
    
    const axisInfoWhite = axisInfo
      .clone(true).lower()
        .attr("fill", "none")
        .attr("stroke", "white")
        .attr("stroke-width", 3);

    this.labelElements = [axisInfo, axisInfoWhite]
    
    circles
      .on('mouseover', event => this.showLabel(event.target.__data__))
      .on('mouseleave', this.removeLabel.bind(this))

    simulation.on("tick", () => {
      this.linkElements.attr("d", this.linkArc.bind(this));
      nodes.attr("transform", d => `translate(${d.x},${d.y})`);
    });

    return svg.node();
  }

  color(node) {
    const scale = d3.scaleOrdinal(this.types, this.schemaColors)

    return scale(node);
  }

  showLabel(node) {
    this.labelElements.forEach(axis => 
      axis.style('display', d => d.id === node.id ? 'block' : 'none')
    )
    this.linkElements.attr("stroke-width", d => d.source === node ? 1.8 : 1)
  }

  removeLabel() {
    this.labelElements.forEach(axis => axis.style('display', 'none'))
    this.linkElements
      .attr("stroke-width", 1)
      .attr("stroke", d => this.color(d.type))
  }

  linkArc(node) {
    const r = Math.hypot(node.source.x - node.target.x, node.source.y - node.target.y);
    let line = `A${r},${r} 0 0,1 ${node.target.x},${node.target.y}` 
    if (node.isLine)
      line = `L${node.target.x},${node.target.y}`
    return `M${node.source.x},${node.source.y} ${line}`;
  }

  stokeWidth(node){
    const links = this.links.filter(link => link.target == node || link.source == node)

    const target = links.filter(link => link.target == node).length + 1;
    const source = links.filter(link => link.source == node).length + 1;

    const width = this.circleWidth(node, source);

    let stoke = width * (target / source)

    if (stoke > width)
      stoke = width;

    return stoke;
  }

  circleWidth(node, links = undefined) {
    let sourceQty = links;
    if (!links)
      sourceQty = this.links.filter(link => link.source == node).length
    
    const width = this.circleSize * (sourceQty / this.nodes.length);

    return width + this.minCircleSize
  }

  includeLines() {
    this.nodes.push({
      isLine: true,
      id: this.nodes.length + 1,
      label: '',
      x: 0,
      y: 0,
    })
    this.nodes.push({
      isLine: true,
      id: this.nodes.length + 1,
      label: 'RI+CI',
      axisLabel: {
        y: "-0.6em",
        x: -15,
      },
      x: this.maxX,
      y: 0,
    })
    this.nodes.push({
      isLine: true,
      id: this.nodes.length + 1,
      label: '',
      x: 0,
      y: this.maxY,
    })
    this.nodes.push({
      isLine: true,
      id: this.nodes.length + 1,
      label: 'RI-CI',
      axisLabel: {
        y: "-1em",
        x: -7,
      },
      x: 0,
      y: this.minY,
    })

    this.links.push({
      source: this.nodes[this.nodes.length - 4].id,
      target: this.nodes[this.nodes.length - 3].id,
      type: 'arrow',
      isLine: true
    })

    this.links.push({
      source: this.nodes[this.nodes.length - 2].id,
      target: this.nodes[this.nodes.length - 1].id,
      type: 'arrow',
      isLine: true
    })
  }
}