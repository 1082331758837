import { Controller } from "@hotwired/stimulus"

function validateType(types, type){
  if(types == undefined) return true
  if(types.length == 2) return true
  return  types.includes(type)
}
export default class extends Controller {
  static targets = [ "alert","alertMensage"]
  hideAlert(){
    this.alertTarget.setAttribute('hidden','') 
  }
  validateFiles(e) {
    //Return True if type is in types
    const files = e.currentTarget.files
    const maxSize = e.currentTarget.getAttribute('data-max-size')
    const types = e.currentTarget.getAttribute('data-types') // Is a string 
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      if(file.size > maxSize){
        this.alertMensageTarget.innerHTML ='O sistema aceita apenas arquivos de no máximo 25 megabytes. Por favor, tente novamente.'
        e.currentTarget.value = ''
        this.alertTarget.removeAttribute('hidden')
      } 
      if(!validateType(types,file.type)){
        this.alertMensageTarget.innerHTML = 'O sistema aceita apenas anexos do tipo PDF ou pacote Office (Word, Excel, PowerPoint). Por favor, tente novamente.'
        e.currentTarget.value = ''
        this.alertTarget.removeAttribute('hidden')
      }
    }
  }
}