import consumer from "./consumer"

export default class Chat {
  constructor(chat_id, connected, disconnected, received) {
    this.chat = consumer.subscriptions.create({ channel: 'ChatChannel', chat_id }, {
      received,

      connected,
      
      disconnected
    })
  }

  send(message) {
    this.chat.send( { message })
  }

  typing(user) {
    this.chat.perform('typing', { user })
  }

  stopTyping(user) {
    this.chat.perform('stop_typing', { user })
  }
}