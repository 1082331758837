import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['message', 'sendButton', 'content'];

  onInput(event) {
    const target = event.target;
    const value = target.value;

    if(value === '') {
      this.sendButtonTarget.setAttribute("disabled", "disabled");
    } else {
      this.sendButtonTarget.removeAttribute("disabled");
    }
  }

  send(event) {
    event.preventDefault();
    const form = event.target.parentElement.parentElement.parentElement;
    const formData = new FormData(form);
    const path = form.action;
    const body = {};

    for(const props of formData.entries()) {
      body[props[0]] = props [1];
    }

    fetch(path, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        "X-CSRF-Token": body["authenticity_token"],
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.body.getReader())
    .then(reader => reader.read())
    .then(({ value }) => {
      const responseBody = new TextDecoder().decode(value);

      this.contentTarget.insertAdjacentHTML('beforeend', responseBody);
      this.messageTarget.value = '';
    })
  }
}
