import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'content', 'destroy']
  
  edit(event) {
    const id = event.target.dataset.id;
    this.show(id, this.contentTargets)
  }

  delete(event) {
    const id = event.target.dataset.id;
    this.show(id, this.destroyTargets)
  }

  show(id, conditionList) {
    conditionList.forEach(element => {
      element.hidden = element.dataset.id != id;
    });
  }
}