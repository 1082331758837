import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["unity","plus","value"]

  connect(){
    this.metric = {
      type: this.element.dataset.defaultMetric
    }
    this.changeMetric()
  }

  onChange(event){
    const id = event.target.value
    this.plusTarget.hidden = !(id)
    if(id)
      this.getMetric(id)
  }

  async getMetric(id){
    try{
      const url = this.element.dataset.url.replace("unity-id", id)
      const response = await fetch(url+".json");
      const data = await response.json()
      if(data){
        this.metric = data.metric
        this.changeMetric()
      }
    }catch(error){
      console.error(error)
    }
  }

  changeMetric(){
    setTimeout(() => {
      this.valueTargets.forEach(element => {
        element.querySelector("span").innerText = this.metric.type
        if (this.metric.type == 'R$') {
          var divs = document.getElementsByClassName('value')
          for (let i = 0; i < divs.length; i++) {
            let div = divs[i]
            div.classList.remove('value')
            div.classList.add('monetary')
          }  
        }
      })
    }, 5);
  }
}