class Calculation {
  static getAhpDiagnosticAndPrognostic(data, original) {
    const [initalData, ...rest] = data;

    const values = rest.reduce((acc, cur) => {
      const result = {};

      Object.keys(acc).forEach((key) => {
        const currentValue = cur.result.values[key];
        result[key] = {
          as_is: [],
          to_be: [],
          effort: [],
          attributes: original.values[key].attributes,
        };

        acc[key].to_be.forEach((value, index) => {
          const toBe = (value + currentValue.to_be[index]) / 2;
          const asIs = (acc[key].as_is[index] + currentValue.as_is[index]) / 2;
          const effort = toBe - asIs;

          result[key].to_be.push(toBe);
          result[key].as_is.push(asIs);
          result[key].effort.push(effort);
        });
      });

      return result;
    }, initalData.result.values);

    return { ...original, values };
  }

  static getAhpDiagnostic(data, original) {
    const [initalData, ...rest] = data;

    const values = rest.reduce((acc, cur) => {
      const result = {};
      
      Object.keys(acc).forEach((key) => {
        const currentValue = cur.result.values[key];
        console.log(original.values[key])
        result[key] = acc[key].map((value, index) => ({
          ...original.values[key][index],
          value: (value.value + currentValue[index].value) / 2,
          global_weight:
            (value.global_weight + currentValue[index].global_weight) / 2,
          consistency_ratio:
            (value.consistency_ratio + currentValue[index].consistency_ratio) /
            2,
        }));
      });

      return result;
    }, initalData.result.values);

    return { ...original, values };
  }

  static getTopsis(data, original) {
    const [initalData, ...rest] = data;

    let values = rest.reduce((acc, cur) => {
      const result = {};

      Object.keys(acc).forEach((key) => {
        result[key] = (acc[key] + cur.result[key]) / 2;
      });

      return result;
    }, initalData.result);

    values = Object.keys(values).map((key) => {
      const alternative = original.values.find(
        (value) => value.alternative_id === String(key)
      );
      return {
        value: values[key],
        alternative_id: key,
        alternative_name: alternative?.alternative_name ?? "",
      };
    });

    return { ...original, values };
  }

  static getDematel(data, original) {
    const [initalData, ...rest] = data;

    const values = rest.reduce((acc, { result }) => {
      const x = acc.x.map((value, index) => (value + result.x[index]) / 2);
      const y = acc.y.map((value, index) => (value + result.y[index]) / 2);
      const ci = acc.ci.map((value, index) => (value + result.ci[index]) / 2);
      const ri = acc.ri.map((value, index) => (value + result.ri[index]) / 2);
      const absolute = acc.absolute.map(
        (value, index) => (value + result.absolute[index]) / 2
      );
      const percentage = acc.percentage.map(
        (value, index) => (value + result.percentage[index]) / 2
      );
      const alfa = (acc.alfa + result.alfa) / 2;

      return {
        x,
        y,
        ci,
        ri,
        absolute,
        percentage,
        alfa,
      };
    }, initalData.result);

    return { ...original, values };
  }

  static getEvaluation(data, original) {
    const [initalData, ...rest] = data;

    let average_pillar = 0;
    let average_sub_pillar = 0;

    const values = rest
      .reduce((acc, { result }) => {
        return acc.map((value, index) => (value + result.values[index]) / 2);
      }, initalData.result.values)
      .map((value, index) => {
        if (original.values[index].parent) average_sub_pillar += value;
        else average_pillar += value;

        return {
          ...original.values[index],
          value,
        };
      });

    average_pillar /= values.filter((a) => !a.parent).length;
    average_sub_pillar /= values.filter((a) => a.parent).length;

    return { ...original, values, average_pillar, average_sub_pillar };
  }
}

export { Calculation };
