import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 
    'graph',
    'info',
    'section',
    'next',
    'previous',
    'submit',
    'title',
    'objective',
    'step',
    'stepTitle'
  ]

  initialize() {
    this.showGraph = true;
    this.index = 0;
    this.answered = 0;
    this.qty = this.sectionTargets.length;
    this.ctx = this.graphTarget.querySelector('.graph');

    this.generateDatas();
    this.generateColors();
    this.getColors();
    this.generateGraph();
    this.show();
  }

  generateDatas(){
    const infos = [];
    let levelData = [];
    let level = 2;
    this.infoTargets.map(({ dataset }) => {
      const currentLevel = Number(dataset.level)
      const children = JSON.parse(dataset.children) ?? []
      const childrenIds = JSON.parse(dataset.childrenIds) ?? []
      
      if(level !== currentLevel) {
        level = currentLevel;
        infos.push(levelData);
        levelData = [];
      }
      
      levelData.push({
        ...dataset,
        level,
        children,
        childrenIds,
        id: Number(dataset.id),
        qty: childrenIds.length,
        colorSize: childrenIds.length,
      })
    });
    infos.push(levelData);

    this.datas = [];

    infos.reverse().map((currentLevel, index) => {
      if(index === 0)
        this.datas.push(currentLevel.map(pillar => ({...pillar, qty: 1 })))
      else if(index === 1)
        this.datas.push(currentLevel.map(pillar => ({...pillar, qty: pillar.childrenIds.length})));
      else {
        const previousLevel = this.datas[this.datas.length - 1];

        const dataSchema = currentLevel.map(pillar => {
          const { qty, colorSize, childrenIds } = previousLevel.filter(
            ({ uniqId }) => pillar.childrenIds.includes(uniqId)
          ).reduce((acc, cur) => {
            return {
            qty: acc.qty + cur.qty,
            colorSize: acc.colorSize + cur.colorSize,
            childrenIds: [...acc.childrenIds, ...cur.childrenIds]
          }}, { qty: 0, colorSize: 0, childrenIds: [] })

          return {
            ...pillar,
            qty,
            colorSize,
            childrenIds
          }
        })

        this.datas.push(dataSchema)
      }
    })

    this.datas = this.datas.reverse();
  }
  
  generateColors(){
    let colors = this.datas[1].map(pillar => ({
      colors: new ColorPallete(pillar.color, '#D3D3D3', pillar.colorSize + 4),
      childrenIds: pillar.childrenIds,
    }));

    this.originalColors = this.datas.map((level, index) => {
      if(index <= 1)
        return level.map(pillar => pillar.color);
      
      return level.map(pillar => {
        const findIndex = colors.findIndex(pillarColor => pillarColor.childrenIds.includes(pillar.uniqId))
        if(findIndex === -1)
          return '#D3D3D3'
        return colors[findIndex].colors.shift();
      })
    })
  }

  getColors(current = 0) {
    let indexForAll = -1;

    this.colors = this.originalColors.map(level => {
      return level.map((color) => {
        indexForAll++;
        if(indexForAll === current)
          return '#b84c2f';
        if (indexForAll < this.answered)
          return "#dca697";
        return color;
      });
    })
    this.qtyIndex = indexForAll;
  }

  generateGraph() {
    const chartOptions = {
      aspectRatio: 1,
      tooltips: {
        enabled: true,
        intersect: true,
        displayColors: false,
        bodyFontSize: 16,
        bodyFontStyle: 'bold',
        callbacks: {
          label: function(item, data) {
            return data.labels[item.datasetIndex][item.index];
          },
        }
      },
      legend: {
        display: false,
      },
      responsive: false,
      layout: {
        padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20
        }
      },
      onClick: (evt, item) => {
        if(item[0]) {
          const pillar = this.datas[item[0]._datasetIndex][item[0]._index]
          if(pillar.id <= this.answered) {
            this.selectGraph(pillar.id)
          }
        }
      }
    }

    this.chart = new Chart(this.ctx, {
      type: 'doughnut',
      responsive: true,
      data: {
        labels: this.datas.map(level => level.map(item => item.title)),
        datasets: this.datas.map((level, index) => ({
          data: level.map(item => item.qty),
          label: index + 1,
          backgroundColor: this.colors[index],
          borderColor: '#FFF',
          borderWidth: 1,
          pointBackgroundColor: '#fff',
          pointBorderColor: '#b84c2f',
          pointBorderWidth: 4,
          pointRadius: 5,
          pointStyle: 'circle',
          pointHoverRadius: 5
        })),
      },
      options:  chartOptions
    });
  }

  selectGraph(current) {
    this.getColors(current);
    this.index = current;
    this.chart.data.datasets = this.chart.data.datasets.map((dataset, index) => {
      return {
        ...dataset,
        backgroundColor: this.colors[index]
      }
    });
    this.info(this.index);
  }

  update(event) {
    const { value } = event.currentTarget.dataset;

    const current = this.index + Number(value);
    const isPossible = current >= 0 && current < this.qty;
    this.showGraph = !this.showGraph;

    if (isPossible &&
      ( (current > this.index && this.showGraph) || 
      (current < this.index && !this.showGraph) ) ) {
        this.answered = this.answered < current ? current : this.answered;
        this.selectGraph(current)
    }

    this.show();
    this.emitter('change')
  }

  generateSteps(children) {
    return children.reduce((acc, cur, index) => acc + `
      <div class="triangle mt-1 ${index % 2 === 0 ? 'alt' : ''} d-flex">
        <p class="pl-5 pr-4 m-0">${cur}</p>
        <span class="before alt"></span>
        <span class="before"></span>
      </div>
    `, '')
  }

  generateStepTitle(value) {
    if (value === 2)
      return 'Pilares'
    else if (value === 3)
      return 'Critérios'
    else
      return 'Subcritérios'
  }

  info(id) {
    this.infoTargets.map(info => {
      if(info.dataset.id == id ) {
        const { objective, title, level } = info.dataset;
        this.objectiveTarget.innerText = objective;
        this.titleTarget.innerText = title;
        this.stepTitleTarget.innerText = this.generateStepTitle(Number(level))
        this.stepTarget.innerHTML = this.generateSteps(JSON.parse(info.dataset.children));
      }
    })
    this.chart.update();
  }

  show(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.previousTarget.hidden = this.index === 0 && this.showGraph;
    this.nextTarget.hidden = this.index === this.qty - 1 && !this.showGraph;
    this.submitTarget.hidden = this.showGraph || this.index !== this.qty - 1;

    this.graphTarget.hidden = !this.showGraph;
    this.sectionTargets.map((section, index) => {
      section.hidden = this.showGraph || this.index !== index;
    });

    this.info(this.index);
  }

  emitter(type, detail = undefined) {
    document.dispatchEvent(
      new CustomEvent(`${type}:pagination`, { detail })
    );
  }
}