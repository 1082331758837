import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ 'input', 'time' ]

  initialize() {
    this.toast = document.querySelector('#toast')
    this.localStorageKey = {
      data: `${window.location}_${this.element.dataset.user}`,
      time: `${window.location}_${this.element.dataset.user}:time`
    }
    this.showMessage = true;
    this.timeStatus = {
      seconds: Number(localStorage.getItem(this.localStorageKey.time) ?? 0),
      started: Date.now(),
      addInInput: true,
    }

    if (!this.hasTimeTarget) this.timeStatus = null

    this.setFormData();
    this.inputTargets.forEach(input => {
      if (input.hasAttribute('input')) {
        input.addEventListener('trix-change', this.save.bind(this))
      }
    })

    setTimeout(() => this.emitter('setup'), 1)
  }

  disconnect() {
    this.inputTargets.forEach(input => {
      if (input.hasAttribute('input')) {
        input.removeEventListener('trix-change', this.save.bind(this))
      }
    })
    this.setTime();
  }

  getFormData() {
    const data = {}
    this.inputTargets.forEach(input => {
      if (input.hasAttribute('input')) {
        const inputHidden = input.parentElement.querySelector('input[type="hidden"]')

        data[input.getAttribute('input')] = inputHidden?.value ?? '';
      } else {
        data[input.name] = input.value;
      }
    })
    return data;
  }

  save() {
    const data = this.getFormData();
    localStorage.setItem(this.localStorageKey.data, JSON.stringify(data));
    if(this.showMessage) {
      this.showMessage = false;
      this.notify({
        title: 'Salvamento automático',
        message: 'A avaliação está sendo salva a cada alteração nesse dispositivo.'
      })
    }
  }

  setFormData() {
    const storage = localStorage.getItem(this.localStorageKey.data);
    if(storage) {
      const data = JSON.parse(storage);
      this.inputTargets.forEach(input => {
        const hasAttribute = input.hasAttribute('input')
        const name = hasAttribute ? input.getAttribute('input') : input.name;

        const value = data[name];

        if(typeof value !== 'undefined') {
          if (hasAttribute) {
            input.innerHTML = value;
          } else if (input.type === 'number')
            input.setAttribute('value', value);
          else
            input.value = value;
        }
      })
      this.notify({
        title: 'Salvamento automático',
        message: 'Dados carregados com sucesso'
      })
    }
  }

  clear() {
    localStorage.removeItem(this.localStorageKey.data);
    localStorage.removeItem(this.localStorageKey.time);
    this.timeStatus = null;
  }

  time(event) {
    if (!this.timeStatus?.addInInput || !this.hasTimeTarget) return;

    event.preventDefault()
    this.timeStatus.addInInput = false;

    const { target } = event;

    this.setTime(false);
    this.timeTarget.value = parseInt(this.timeStatus.seconds / 60);

    Rails.fire(target, 'submit')
  }
  

  setTime(setNull = true) {
    if (this.timeStatus) {
      const { started, seconds } = this.timeStatus;
      const finished = Date.now()

      const diff = finished - started;
      const currentSeconds = parseInt(diff / 1000);
      this.timeStatus.seconds = seconds + currentSeconds;
      this.timeStatus.started = finished;

      localStorage.setItem(this.localStorageKey.time, this.timeStatus.seconds)

      if (setNull)
        this.timeStatus = null;
    }
  }

  emitter(type, detail = undefined) {
    document.dispatchEvent(
      new CustomEvent(`${type}:auto-save`)
    );
  }

  notify({title, message}) {
    this.toast.setAttribute('data-delay', 10000)
    this.toast.querySelector('.toast-title').innerText = title;
    this.toast.querySelector('.toast-body').innerText = message;
    $(toast).toast('show')
  }
}