import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ 'tdoutput' ];

  mouseEnter(e) {
    const context = e.target;
    const circles = context.children;
    for(let circle of circles) {
      circle.classList.remove('d-none');
    }
  }

  mouseLeave(e) {
    const context = e.target;
    const circles = context.children;
    for(let circle of circles) {
      if(!circle.classList.contains('current-status'))
        circle.classList.add('d-none')
    }
  }

  async click(e) {
    const target = e.target;
    const innerDivParent = target.parentNode;
    const outerDivParent = innerDivParent.parentNode;
    const tableParent = outerDivParent.parentNode;
    const feedbackUrl = outerDivParent.dataset.feedbackUrl;

    await fetch(feedbackUrl + `?status=${target.dataset.status}`,  {
      method: 'PUT',
    })
      .then(() => {
        this.tdouptTarget = this.tdoutputTargets
          .find(target => target.dataset.feedbackId == tableParent.dataset.feedbackId);

          for(let innerDiv of outerDivParent.children) {
            innerDiv.classList.remove('current-status');
          }

          innerDivParent.classList.add('current-status');
      })
  }

  getColorFromClassList(target) {
    for(let color of ['green', 'yellow', 'red']) {
      if(target.classList.contains(`text-${color}`))
        return color;
    }
  }
}
