import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  change_msg(event){
    //event.currentTarget.setAttribute("title",event.currentTarget.value);
    $(event.currentTarget).tooltip('hide')
      .attr('data-original-title', "Valor: " + event.currentTarget.value)
      .tooltip('show');
      
  }
}
