import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['indicator', 'category']

  connect() {
    this.selectedValue = this.indicatorTarget.value
    this.url = this.element.dataset.url;
    if (this.categoryTarget.value) {
      this.enableInput();
      this.fetchData(this.categoryTarget.value)
    } else {
      this.disabledInput();
    }
  }

  onChange(event) {
    const {target} = event;
    const {value} = target;

    if (!value) {
      this.disabledInput();
      return;
    } else {
      this.enableInput();
      this.fetchData(value);
    }
  }

  enableInput() {
    this.indicatorTarget.disabled = false;
  }

  disabledInput() {
    this.indicatorTarget.disabled = true
    this.insertOptions([])
  }

  async fetchData(id) {
    try {
      const url = this.url.replace('indicator-category-id', id)
      const response = await fetch(url);
      const indicators = await response.json();
      if (indicators.length == [])
        this.disabledInput();
      this.insertOptions(indicators);
    } catch (err) {
      this.disabledInput();
    }
  }

  insertOptions(indicators) {
    if (this.indicatorTarget)
      this.indicatorTarget.selected = true;

    this.indicatorTarget.innerHTML = '';
    let opt = document.createElement('option');
    opt.value = "";
    opt.innerHTML = "Por favor selecione";
    this.indicatorTarget.appendChild(opt);

    if (this.indicatorTarget)
      this.indicatorTarget.selected = true;
   
    indicators.forEach(ind => {
      const opt = document.createElement('option');
      opt.value = ind.id;
      opt.innerHTML = ind.name;
      this.indicatorTarget.appendChild(opt);
      if (this.selectedValue == ind.id)
        opt.selected = true;
    })


  }
}