import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {path: String, target: String, param: String}

    loadOptionsForTarget() {
        const path = `${this.pathValue}?${this.paramValue}=${this.element.value}`;
        const target = document.getElementById(this.targetValue);

        fetch(path)
            .then(response => response.text())
            .then(data => {
                target.innerHTML = data.toString();

                if (target.hasAttribute('data-options-for-select-async-target-value')) {
                    const childId = target.getAttribute('data-options-for-select-async-target-value');
                    const childTarget = document.getElementById(childId)

                    childTarget.disabled = true
                    childTarget.innerHTML = ''

                    const childOption = document.createElement('option');

                    childOption.value = '';
                    childOption.innerHTML = 'Por favor selecione';

                    childTarget.appendChild(childOption);
                }

                target.disabled = target.getElementsByTagName('option').length === 1;
            })
    }
}
