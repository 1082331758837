import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'statusList'];

  enter() {
    for(const status of this.statusListTarget.children) {
      status.classList.remove('d-none');
    }
  }

  leave() {
    for(const status of this.statusListTarget.children) {
      if(!status.classList.contains('isVisible')) {
        status.classList.add('d-none');
      }
    }
  }

  async click(event) {
    event.stopPropagation();
    const target = event.target;
    const status = event.target.dataset.status;
    const path = this.formTarget.action;
    const authetication_token = new FormData(this.formTarget).get('authenticity_token');

    await fetch(path, {
      method: 'PUT',
      body: JSON.stringify({ status: status }),
      headers: {
        "X-CSRF-Token": authetication_token,
        'Content-Type': 'application/json'
      }
    })
    .then(() => {
      for(const status of this.statusListTarget.children) {
        status.classList.remove('isVisible');
      }

      target.parentNode.classList.add('isVisible');

      for(const status of this.statusListTarget.children) {
        if(!status.classList.contains('isVisible')) {
          status.classList.add('d-none');
        }
      }

    });

  }
}
