import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ 'input', 'button' ]

  connect() {
    this.limit = Number(this.element.dataset.limit || "5")
    this.clicked = this.inputTargets.length;
    this.hiddenButton();
  }

  hiddenButton() {
    this.buttonTarget.hidden = this.clicked >= this.limit;
  }

  click(){
    this.clicked++;
    this.hiddenButton();
  }

  removeInput() {
    this.clicked--;
    this.hiddenButton();
  }
}