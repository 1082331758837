import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'input', 'value' ]

  autoSave() {
    this.inputTargets.forEach(input => {
      input.checked = input.value === this.valueTarget.value;
    })
  }

  change(event) {
    const { value } = event.target;

    if (this.valueTarget.value === value)
      this.valueTarget.value = null;
    else
      this.valueTarget.value = value;

    this.autoSave();
  }
}