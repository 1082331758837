import { Controller } from "@hotwired/stimulus"

const values = {
  overview: 'overview',
  roadmap: 'roadmap',
  history: 'history',
};

export default class extends Controller {
  static values   = { url: String, status: String };
  static targets = [ 'pages', 'buttons', 'editable', 'icon'];

  initialize() {
    this.type = values[this.element.dataset.overview || 'overview']
    this.show();
  }

  change(event) {
    if (!this.pagesTargets.length) return;

    event.preventDefault();
    const { value } = event.target.dataset;

    this.type = values[value || 'history'];
    this.show();
  }

  show() {
    this.buttonsTargets.forEach(button => {
      button.classList.toggle('disable', this.type !== button.dataset.value)
    })
    this.pagesTargets.forEach(page => {
      page.hidden = this.type !== page.dataset.value;
    })
  }

  enter(event) {
    this._toggle(event);
  }

  leave(event) {
    this._toggle(event);
  }

  update(event) {
    const url = event.target.dataset.url;
    const status = event.target.dataset.status;

    fetch(url + `?status=${status}`, {
      method: 'PUT'
    })
      .then(() => document.location.reload(true))
  }

  _toggle(event) {
    event.stopPropagation();
    Array.prototype.slice.call(event.target.children).forEach((child) => {
      if(child.dataset.visible == "false") {
        child.classList.toggle('d-none');
      }
    })
  }
}
