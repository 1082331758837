import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.interval = null;
    this.text = "";
    this.callback = () => {};
    this.initializeTribute()
  }

  disconnect () {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'full_name',
      values: this.fetchUsers,
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchUsers(text, callback) {
    if (Boolean(this.interval)) {
      clearTimeout(this.interval);
    }
    let [firstName, lastName] = text.split(' ')
    const listOfUsers = () => {
      fetch(`mentions.json?firstName=${firstName}&lastName=${lastName || ""}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
      .finally(() => this.interval = null)
    }
    this.interval = setTimeout(listOfUsers, 500);
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - (endPos - startPos), position])
    this.editor.deleteInDirection("backward")
  }
}