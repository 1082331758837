import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['group', 'button', 'legend']

  initialize() {
    this.generateGrap(this.element.querySelector('.group-graph'))
    this.index = -1
  }
  generateGrap(ctx){
    const chartOptions = {
      aspectRatio: 1,
      tooltips: {
        enabled: true,
        intersect: true,
        displayColors: false,
        bodyFontSize: 24,
        bodyFontStyle: 'bold',
        callbacks: {
          label: function(tooltipItem, data) {
            return data.labels[tooltipItem.index];
          },
        },
      },
      legend: {
        display: false
      },
      responsive: true,
      layout: {
        padding: {
          left: 50,
          right: 50,
          top: 50,
          bottom: 50,
        }
      },
      onClick: (evt, item) => {
        this.chart.update();
        if(item[0]) {
          this.update(item[0]._index)
          item[0]._model.outerRadius += 10
        } else this.update(-1)
      }
    }
    const labels = $(ctx).data('labels')
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      responsive: true,
      data: {
        labels: labels.map(label => label.name),
        datasets: [{
          data: $(ctx).data('values'),
          backgroundColor: $(ctx).data('colors'),
          borderColor: '#000',
          borderWidth: 2,
          pointBackgroundColor: '#fff',
          pointBorderColor: '#b84c2f',
          pointBorderWidth: 4,
          pointRadius: 5,
          pointStyle: 'circle',
          pointHoverRadius: 5
        }]
      },
      options:  chartOptions
    });
    this.legendTarget.innerHTML = this.chart.generateLegend();
  }

  update(id) {
    const check = id < 0;
    this.buttonTarget.hidden = check;

    if(!check) {
      this.groupTargets.forEach((element, index) => {
        if(index === id){
          this.buttonTarget.href = this.buttonTarget.dataset.url + '/' + element.dataset.id
          this.buttonTarget.innerText = 'Selecionar ' + element.dataset.name
        }
      });
    }
  }
}