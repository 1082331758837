import consumer from "./consumer"

consumer.subscriptions.create("WebNotificationsChannel", {
  received(data) {
    this.notify(data)
  },

  notify({ title, body } = { title: '', body: '' }) {
    const toast = document.querySelector('#toast')

    toast.setAttribute('data-delay', 4000)
    toast.querySelector('.toast-title').innerText = title;
    toast.querySelector('.toast-body').innerText = body;

    $(toast).toast('show')
  }
})