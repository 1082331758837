import { Controller } from "@hotwired/stimulus";
import { Calculation } from "../../packs/benchmarking/calculation";

export default class extends Controller {
  static values = { result: Object };

  filterChange({ detail: { datas, original, filter } }) {
    let datasParsed = original;

    if (filter) {
      datasParsed = Calculation.getTopsis(datas, original);
    }

    this.resultValue = datasParsed;
  }

  resultValueChanged() {
    if (!this.resultValue.values) return;

    this.element.innerHTML = "";

    this.element.appendChild(this.content);
  }

  get content() {
    const div = document.createElement("div");
    
    div.setAttribute("class", "col-5 justify-content-center collapse show");

    return div;
  }
}
