import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['indicator', 'legend']

  initialize() {
    this.ctx = this.indicatorTarget
    this.getData()
    this.generateGrap()
  }
  generateGrap(){
    const config = {
      type: 'bar',
      data: this.graph,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          }
        }
      },
    };
    this.chart = new Chart(this.ctx, config)
  }

  getData(){
    let { goalValues, hitValues, labels } = this.ctx.dataset;
    goalValues = JSON.parse(goalValues)
    hitValues = JSON.parse(hitValues)
    labels = JSON.parse(labels)

    this.graph = {
      labels: labels.map(label => label.name),
      datasets: [
        {
          label: 'Objetivo',
          data: goalValues,
          borderColor: 'rgb(0,0,0)',
          backgroundColor: 'rgb(88, 214, 141)',
          order: 1
        },
        {
          label: 'Atingido',
          data: hitValues,
          borderColor: 'rgb(93, 173, 226)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          type: 'line',
          order: -1
        }
      ]
    };
  }
}