import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'result' ]

  onChange(event) {
    const { currentTarget } = event;

    if (Number(currentTarget.value) !== Number(currentTarget.dataset.value)) {
      currentTarget.classList.add('change')
    } else {
      currentTarget.classList.remove('change')
    }

    const inputs = Array.from(currentTarget.parentElement.querySelectorAll('input'));

    const value = inputs.reduce((acc, cur) => {
      if (cur.name.includes('hidden'))
        return typeof acc === 'undefined' ? cur.value : Number(cur.value) * acc;

      return acc;
    }, undefined)

    inputs.forEach(input => {
      if (!input.name.includes('hidden'))
        input.value = value * 1000;
    })
  }

  insertResult() {
    const originalResult = this.resultTargets.filter(result =>
      result.dataset.orignal === 'true'
    )

    this.resultTargets.forEach(label => {
      const { name, value } = label.dataset;
      const labelOriginal = originalResult.find(result => result.dataset.name === name)

      if(!labelOriginal) return;
      
      if(value !== labelOriginal.dataset.value) {
        label.classList.add('change')
      } else {
        label.classList.remove('change')
      }
    });
  }
}