import { Controller } from "@hotwired/stimulus";
import { Calculation } from "../../packs/benchmarking/calculation";

export default class extends Controller {
  static values = { result: Object };

  filterChange({ detail: { datas, original, filter } }) {
    let datasParsed = original;

    if (filter) {
      datasParsed = Calculation.getTopsis(datas, original);
    }

    datasParsed.values.sort((a, b) => b.value - a.value);

    this.resultValue = datasParsed;
  }

  resultValueChanged() {
    if (!this.resultValue.values) return;

    this.element.innerHTML = "";

    this.element.appendChild(this.content);
  }

  get content() {
    const parent = document.createElement("div");
    const div = document.createElement("div");
    
    parent.setAttribute("class", "row topsis");
    parent.setAttribute("data-controller", "topsis");
    parent.setAttribute("data-action", "setup:heatmap@document->topsis#setup");
    parent.setAttribute("data-id", "#page-graph");
    parent.appendChild(div);
    parent.appendChild(this.graph);

    div.setAttribute("class", "col-5 justify-content-center collapse show");
    div.setAttribute("id", "page-collapse-label");

    div.appendChild(this.ul);

    return parent;
  }

  get graph() {
    const div = document.createElement("div");
    div.setAttribute("class", "col");

    const children = document.createElement("div");
    children.setAttribute("class", "topsis-fix");
    children.setAttribute("id", "page-graph");

    div.appendChild(children);

    return div;
  }

  get ul() {
    const ul = document.createElement("ul");

    this.li.forEach((li) => ul.appendChild(li));

    ul.setAttribute("class", "list-group");
    ul.setAttribute("data-controller", "heatmap");

    return ul;
  }

  get li() {
    return this.resultValue.values.map((item, index) => {
      const li = document.createElement("li");

      li.setAttribute(
        "class",
        "label list-group-item d-flex justify-content-between align-items-center p-2"
      );
      li.setAttribute("data-topsis-target", "label");
      li.setAttribute("data-name", `A${index + 1}`);
      li.setAttribute("data-value", item.value);
      li.setAttribute("data-id", index);

      li.innerHTML = `
        <span class="anchor"></span>
        <b>A${index + 1}</b> ${item.alternative_name}
        <span
          class="badge badge-pill"
          data-heatmap-target="item"
          data-topsis-target="color"
          data-value="${item.value}"
          data-id="${index}"
        >
          ${(item.value * 100).toFixed(2)}%
        </span>
      `;

      return li;
    });
  }
}
