import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'button', 'file' ]

  connect() {
    this.ids = [];
    this.buttonTarget.hidden = true;
    this.url = this.buttonTarget.getAttribute('href')
  }

  click(event) {
    const { currentTarget } = event;
    const { id } = currentTarget.dataset

    const isActive = currentTarget.parentElement.classList
      .toggle('table-active')

    if (isActive) this.ids.push(id);
    else this.ids = this.ids.filter(current => current !== id);

    const hasIds = Boolean(this.ids.length);

    this.buttonTarget.hidden = !hasIds
    const queryParams = hasIds ? encodeURI(`ids[]=${this.ids.join('&ids[]=')}`) : '';
    this.buttonTarget.setAttribute(
      'href',
      `${this.url}?${queryParams}`
    );
  }

}