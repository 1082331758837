import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['range', 'output', 'input', 'option', 'progress', 'trigger']

  connect() {
    this.type = this.element.dataset.type;
    this.onlyRead = this.element.dataset.read === 'true'
    this.max = Number(this.element.dataset.max || 2);
    this.middle = Math.ceil(this.max / 2);

    if (this.element.dataset.autoSave !== 'true')
      this.onChange();
  }

  isVisible() {
    return Boolean(this.element.offsetHeight);
  }

  autoSave(e) {
    if (this.type === 'withoutMiddle')
      this.rangeTarget.value = this.inputTarget.value;
    else
      this.rangeTarget.value = this.max - Number(this.inputTarget.value);
    this.onChange(e);
  }

  onChange(e) {
    if ((e?.type === 'change' && this.onlyRead) || !this.isVisible())
      return;
    if (this.type === 'withoutMiddle')
      this.onChangeWithoutMiddle(e);
    else
      this.onChangeWithMiddle(e);
  }

  onChangeWithoutMiddle() {
    const value = Number(this.rangeTarget.value);
    this.inputTarget.value = value;

    this.optionTargets.forEach(opt => {
      const optValue = Number(opt.value);
      if (value >= optValue)
        opt.style.backgroundColor = '#b84c2f';
      else
        opt.style.backgroundColor = '#aaa';
    });

    const progressPercent = (value / this.max);
    this.progressTarget.style = `background-image: -webkit-gradient(linear, 0% 0%, 100% 0%,
      color-stop(${progressPercent}, #b84c2f),
      color-stop(${progressPercent}, #aaa)
      );`;

    this.outputConfig()
  }

  onChangeWithMiddle() {
    const value = this.max - Number(this.rangeTarget.value);
    this.inputTarget.value = value;
    set_color(this.element, value);

    this.optionTargets.forEach(opt => {
      const optValue = this.max - Number(opt.value);
      if ((value >= this.middle && optValue >= this.middle && optValue <= value) ||
          (value <= this.middle && optValue <= this.middle && optValue >= value))
        opt.style.backgroundColor = '#b84c2f';
      else
        opt.style.backgroundColor = '#aaa';
    });

    this.progressTargets.forEach(progress => {
      if(progress.classList.contains('left')) {
        const calc = 1 - ((value - this.middle) / this.middle);
        const percentage = value > this.middle ? calc : 1;
        progress.style = `background-image: -webkit-gradient(linear, 0% 0%, 100% 0%,
          color-stop(${percentage}, #aaa),
          color-stop(${percentage}, #b84c2f)
          );`;
      } else {
        const calc = 50 * (value / this.middle); 
        const percentage = value < this.middle ? calc : 50;
        progress.style = `width: ${percentage}%`;
      }
    })

    this.outputConfig()
  }

  outputConfig() {
    const selectedOpt = this.optionTargets.find(opt => opt.value === this.rangeTarget.value);
    this.outputTarget.innerText = selectedOpt.getAttribute('title');
    this.outputTarget.style.left = '50%';
    this.outputTarget.style.left = (( selectedOpt.offsetLeft + selectedOpt.offsetWidth / 2 ) - this.outputTarget.offsetWidth / 2 ) + 'px';
  }
}
