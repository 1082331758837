import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['picker']

  connect() {
    this.colorPickers = this.pickerTargets.map((element) => {
      const picker = new window.iro.ColorPicker(element, {
        width: 280,
        color: this.defaultValue,
        layout: [
          {
            component: iro.ui.Box,
            options: {
              boxHeight: 160
            }
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: 'hue',
            }
          },
        ]
      })

      picker.on('input:change', (color) => {
        this.dispatch('change', {
          detail: {
            color: color.hexString,
            element
          }
        })
      })

      picker.on('color:change', (color) => {
        this.dispatch('change', {
          detail: {
            color: color.hexString,
            element
          }
        })
      })

      return picker;
    })

  }
}