class Font {
  constructor(size) {
    this.size = size
  }

  get rem() {
    return `${this.size * 0.0625}rem`
  }

  get px() {
    return `${this.size}px`
  }

  static rawNumberFrom(fontSizeString) {
    return Number.parseInt(fontSizeString)
  }
}

export { Font }