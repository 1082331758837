import { Controller } from "@hotwired/stimulus"
import { Notify } from '../packs/notify'

export default class extends Controller {
  static targets = [ 'question' ]

  connect() {
    this.notify = new Notify(this.identifier)
  }

  change(event) {
    const { target } = event;

    const disable = target.checked;
    let { to, by } = target.dataset;

    to = String(to ?? '')
    by = String(by ?? '')

    if (!to || !by) return;

    let startDisable = false;
    let endDisable = false;

    this.questionTargets.forEach(question => {
      if (!endDisable) {
        let { id } = question.dataset;
        id = String(id);

        if (startDisable) {
          if (to === id) {
            endDisable = true;
            const input = question.querySelector('input')
            this.notify.emitter('change-page', { question: input })
          } else {
            const inputs = question.querySelectorAll('input');
      
            inputs.forEach(input => {
              input.toggleAttribute('disabled', disable)
            })
      
            question.hidden = disable;
          }
        }

        if (by === id) startDisable = true;
      }
    })
  }
}