import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "message" ]

  connect() {
    $('.Monetary').mask("#.##0,00", {reverse: true});
    $('.Percentage').mask('##,0%', {reverse: true});
    $('.Hours').mask('00:00:00');
    if (this.hasMessageTarget) this.messageTarget.hidden = true;
  }

  onChange(event) {
    const { value } = event.target;

    if (!this.hasMessageTarget) return;

    const isStrong = !!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[(^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=)]).{8,}$/)

    this.messageTarget.hidden = isStrong;
  }
}