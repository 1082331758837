import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'type', 'group', 'purpose', 'tech', 'methodology']

  initialize(){
    this.update()
    if (this.groupTarget.querySelector('.form-control').value) {
      this.changeGroup({
        currentTarget: this.groupTarget.querySelector('.form-control')
      },
      this.techTarget.querySelector('.form-control').value
      )
    }
    else if (this.purposeTarget.querySelector('.form-control').value) {
      this.changePurpose({
        currentTarget: this.purposeTarget.querySelector('.form-control')
      },
      this.methodologyTarget.querySelector('.form-control').value
      )
    }
  }

  update(){
    const {value} = this.typeTarget
    this.techTarget.hidden = true
    this.techTarget.disabled = true
    this.groupTarget.hidden = true
    this.groupTarget.disabled = true
    this.methodologyTarget.hidden = true
    this.methodologyTarget.disabled = true
    this.purposeTarget.hidden = true
    this.purposeTarget.disabled = true

    if (value === 'AxisTechnologyAssociation'){
      this.groupTarget.hidden = false
      this.groupTarget.querySelector('.form-control').disabled = false
      this.techTarget.hidden = false
    }
    else if (value === 'Methodology'){
      this.purposeTarget.hidden = false
      this.purposeTarget.querySelector('.form-control').disabled = false
      this.methodologyTarget.hidden = false
    }
  }

  async changeGroup(event, selected = -1){
    const group = event.currentTarget
    if (group.value) {
        this.techTarget.querySelector('.form-control').disabled = false
        const response = await fetch(this.groupTarget.dataset.url+ "?id=" + group.value)
        const data = await response.json()
        this.techTarget.querySelector('.form-control').innerHTML = 
        `
        ${ selected == -1 ? '<option value="">Por favor selecione</option>': "" }
        ${data.map(tech => `<option ${ selected == tech.id ? "selected": "" } value="${tech.id}">${tech.name}</option>`).join(' ')}
        `
    }
  }

  async changePurpose(event, selected = -1){
    const purpose = event.currentTarget
    if (purpose.value) {
      this.methodologyTarget.querySelector('.form-control').disabled = false
      const response = await fetch(this.purposeTarget.dataset.url+ "?id=" + purpose.value)
      const data = await response.json()
      this.methodologyTarget.querySelector('.form-control').innerHTML = 
      `
      ${ selected == -1 ? '<option value="">Por favor selecione</option>': "" }
      ${data.map(purpose => `<option ${ selected == purpose.id ? "selected": "" } value="${purpose.id}">${purpose.name}</option>`)}
      `
    }
  }
}