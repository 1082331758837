import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import Sortable from 'sortablejs'

export default class extends Controller {

  static targets = ['group']

  initialize() {
    this.sortable = [];
    this.groupTargets.forEach((group, index) => {
      this.sortable.push(new Sortable(group, {
        onEnd: this.end.bind(this),
        animation: 150,
        group: group.dataset.group || group.dataset.method
      }));
    })
  }

  end(event) {
    switch (event.to.dataset.method) {
      case 'project':
        this.project(event);
        break;
      case 'actions':
        this.actions(event);
        break;
      case 'issues':
        this.issues(event);
        break;
      case 'topsis':
      case 'dematel':
      case 'generic':
        this.generic(event);
        break;
    }
  }

  project(event) {
    if(event.oldIndex === event.newIndex) return;

    event.to.querySelectorAll('tr > .index').forEach((element, index) => {
      element.innerText = index + 1;
    })

    const id = event.item.dataset.id
    const data = new FormData();
    data.append('order', event.newIndex + 1)
    data.append('action_plan_id', id)

    Rails.ajax({
      url: this.data.get('url'),
      type: 'PATCH',
      data,
    })
  }

  actions(event) {
    if(event.from === event.to) return;

    const data = new FormData();
    data.append('action_plan_type_id', event.to.dataset.type);
    data.append('drag', true);
    
    Rails.ajax({
      url: event.item.dataset.url,
      type: 'PATCH',
      data,
    })
  }

  issues(event) {
    if(event.from === event.to) return;

    const data = new FormData();
    data.append('status', event.to.dataset.type);
    data.append('drag', true);

    Rails.ajax({
      url: event.item.dataset.url,
      type: 'PATCH',
      data,
    })
  }

  async generic(event) {
    if(event.oldIndex === event.newIndex) return;

    const { item } = event;

    const indexs = event.to.querySelectorAll('tr > .index') || []
    indexs.forEach((element, index) => {
      element.innerText = index + 1;
    })

    const data = new FormData();
    
    data.append('drag_sequence[sequence]', event.newIndex + 1);
    data.append('drag_sequence[model_id]', item.dataset.id);
    data.append('drag_sequence[model_type]', item.dataset.modelType);

    let url = this.data.get('url');
    if (item.dataset.customId)
      url = url.replace('custom-id', item.dataset.customId)

    Rails.ajax({
      url: url,
      type: 'PATCH',
      data,
    })
  }
}