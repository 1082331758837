import { Controller } from "@hotwired/stimulus"
import {
  select,
  min,
  max,
  scaleLinear,
  forceCollide,
  forceLink,
  forceManyBody,
  forceX,
  forceY,
  forceSimulation,
  zoom,
} from 'd3'

export default class extends Controller {
  static values = { url: String }

  initialize() {
    fetch(this.element.dataset.url + '.json')
      .then(response => response.json())
      .then(({nodes, connections, color, text}) => {
        if (nodes.length <= 2) return;

        this.color = color;
        this.colorText = '#fff'
        const links = this.createLinks(connections, nodes);

        const minLevel = min(nodes, node => node.y);
        const maxLevel = max(nodes, node => node.y);
        const maxFamilies = max(nodes, node => node.x);

        const width = maxFamilies * 400;
        const padding = 10;
        const margin = ({top: padding * 6, right: padding * 5, bottom: padding, left: padding});
        const height = maxLevel * 150;
        const chart_width = width - margin.left - margin.right;
        const chart_height = height - margin.top - margin.bottom;
        const radius = 10;

        const scaleX = scaleLinear().range([0, chart_width]).domain([0, maxFamilies])
        const scaleY = scaleLinear().range([margin.top, chart_height]).domain([minLevel, maxLevel])
        
        const simulation = forceSimulation(nodes)
          .force('collide', forceCollide().radius(node => radius + padding))
          .force('xAxis', forceX(node => scaleX(node.x)).strength(1))
          .force('yAxis', forceY(node => scaleY(node.y)).strength(1))
          .force("charge", forceManyBody().distanceMin([radius + padding]).distanceMax([padding * 4]).strength(1))
          .force("link", forceLink(links).id(node => node.id).strength([0]))
          .alpha(1);

        select("#transformation_roads").html('');

        const tree = select("#transformation_roads")
          .append('svg')
          .attr('id', 'tree')
          .attr("viewBox", [0, 0, width, height]);

        const treeLinks = tree
          .append('g')
          .attr('class', 'links')
          .attr('transform',`translate(${margin.left}, ${margin.top}`);
        
        const treeNodes = tree
          .append('g')
          .attr('class', 'nodes')
          .attr('transform',`translate(${margin.left}, ${margin.top}`);

        const treeTexts = tree
          .append('g')
          .attr('class', 'texts')
          .attr('transform',`translate(${margin.left}, ${margin.top}`);

        const drawLinks = treeLinks
          .selectAll("line")
          .data(links)
          .join("path")
          .style('display', node => node.hide)
          .style('fill', 'none')
          .style("stroke-width", 2)
          .style('stroke', node => node.color);

        const drawTexts = treeTexts
            .selectAll("text")
            .data(nodes.filter(node => node.id >= 2))
            .join("text")
            .attr('class', node => this.setName(node.name))
            .text(node => node.name)
            .style('fill', '#FFF')
            .style('text-anchor', 'middle')
            .style('z-index', 1)
        
        const drawNodes = treeNodes
          .selectAll("rect")
          .data(nodes.filter(node => node.id >= 2))
          .join("rect")
          .attr('class', node => this.setName(node.name))
          .style("stroke", 'none')
          .style("fill", node => node.color)
          .style('z-index', 0)

          
        const drawImages = tree
          .append('g')
          .attr('class', 'images')
          .selectAll("image")
          .data(nodes.filter(node => node.id < 2))
          .join("image")
          .attr('xlink:href', node => {
            if (node.name !== 'Go44') return this.element.dataset.prize
            return this.element.dataset.go44
          })
          .attr('width', node => node.id === 0 ? 400 : 250)
          .attr('height', node => node.id === 0 ? 400 : 250)

        tree.selectAll("rect")
          .on('mouseover', event => this.mouseOver(event.target.__data__, treeNodes, treeLinks, treeTexts))
          .on('mouseleave', event => this.mouseLeave(treeNodes, treeLinks, treeTexts))
          .on('click', event => this.mouseClick(event.target.__data__));

        tree.selectAll("text")
          .on('mouseover', event => this.mouseOver(event.target.__data__, treeNodes, treeLinks, treeTexts))
          .on('mouseleave', event => this.mouseLeave(treeNodes, treeLinks, treeTexts))
          .on('click', event => this.mouseClick(event.target.__data__));

        tree.call(zoom()
          .extent([[0, 0], [width, height]])
          .scaleExtent([1, 8])
          .on("zoom", event => this.zoomed(event, drawLinks, drawNodes, drawImages, drawTexts)));

        simulation.on("tick", () => {
          drawLinks
            .attr('d', (node, i) => {
              const middle = {
                y: (node.source.y - node.target .y) / 2,
                x:  (node.source.x + node.target .x) / 2,
              }
              return `M${node.source.x}, ${node.source.y} C ${node.source.x},${(node.source.y + node.target.y) / 2} ${node.target.x},${(node.source.y + node.target.y) / 2} ${node.target.x},${node.target.y}`

            });

          drawImages
            .attr('x', node => node.id === 0 ? node.x - 200 : node.x - 125)
            .attr('y', node => node.id === 0 ? node.y - 150 : node.y - 150)

          drawTexts
            .attr('x', node => node.x)
            .attr('y', node => node.y)
          
          drawNodes
            .attr('width', node => this.getWidth(node, treeTexts))
            .attr('height', node => this.getHeight(node, treeTexts))
            .attr("x", node => node.x - (this.getWidth(node, treeTexts) / 2) )
            .attr("y", node => node.y - (this.getHeight(node, treeTexts) / 2));
        });
      })
      .catch((err) => {
        console.error(err)
      })
  }

  getWidth(node, treeTexts) {
    return Math.round(treeTexts.select(`text.${this.setName(node.name)}`).node().getBoundingClientRect().width) + 40 * 2;
  }

  getHeight(node, treeTexts) {
    return Math.round(treeTexts.select(`text.${this.setName(node.name)}`).node().getBoundingClientRect().height) + 40;
  }

  zoomed({transform}, drawLinks, drawNodes, drawImages, drawTexts) {
    drawLinks.attr("transform", transform);
    drawNodes.attr("transform", transform);
    drawImages.attr("transform", transform);
    drawTexts.attr("transform", transform);
  }

  createLinks (links, nodes) {
    const linksSchema = links.map((link, index) => {
      const node = nodes.find(nodeSearch => link.target === nodeSearch.id);
      if (node)
        return ({
          id: index + 1,
          name: node.name,
          source: link.source,
          target: link.target,
          hide: 'inherit',
          parents: link.source,
          color: node.color,
        })
      return null;
    }).filter(node => !!node)

    return linksSchema;
  }

  mouseOver(nodeHover, nodes, links, texts) {
    nodes
      .selectAll('rect')
      .style('fill', node => {
        if (node.name === nodeHover.name || node.type_name === nodeHover.type_name) return this.color;
        return node.color;
      })

    links
      .selectAll('path')
      .style('stroke', node => {
        if(node.target.type_name === nodeHover.type_name ) return this.color;
        return node.target.color
      });

    texts
      .selectAll('text')
      .style('fill', node => {
        if (node.name === nodeHover.name) return this.colorText;
        return '#FFF';
      })
    
  }
    
  mouseLeave(nodes, links, texts) {
    nodes
      .selectAll('rect')
      .style('fill', node => node.color)
    
    links
      .selectAll('path')
      .style('stroke', node => node.target.color);
    
    texts
      .selectAll('text')
      .style('fill', '#FFF' );
  }

  mouseClick(node) {
    if (node.url)
      window.location.href = node.url;
  }

  setName(name) {
    return name.replace(/[^a-zA-Z0-9]/g, '')
  }

  disconnect() {
    select("#transformation_roads").html('');
  }
}