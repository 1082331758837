import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  update(event) {
    const checked = event.currentTarget.querySelector('input').checked
    const id = event.currentTarget.dataset.sprint
    const data = new FormData();
    data.append('sprint_id', checked ? id : 'nil')
    Rails.ajax({
      url: event.currentTarget.dataset.url,
      type: 'PATCH',
      data
    })
  }

}