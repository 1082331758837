import { Controller } from "@hotwired/stimulus"
import Chat from '../channels/chat_channel'

export default class extends Controller {

  static targets = [ 'input', 'button', 'messages', 'peoples' ]

  connect() {
    this.info = {
      user_id: String(this.element.dataset.userId),
      user_name: this.element.dataset.userName,
      team_id: this.element.dataset.teamId
    }
    this.isTyping = false;
    this.chat = new Chat(
      this.info.team_id,
      this._connected.bind(this),
      this._disconnected.bind(this),
      this._received.bind(this)
    );
    this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight);
  }

  _connected() {
    this.inputTarget.removeAttribute('readonly');
    this.buttonTarget.removeAttribute('disabled');
  }
  
  _disconnected() {
    this.inputTarget.setAttribute('readonly', '');
    this.buttonTarget.setAttribute('disabled', '');
  }

  _received(data) {
    const { action } = data;

    switch (action) {
      case 'typing':
        this._typing(data.user);
        break;
      case 'stop_typing':
        this._stopTyping(data.user);
        break;
      default:
        this._receibedMessage(data);
    }
  }
      
  _typing(user) {
    if (String(user.user_id) === this.info.user_id)
      return;

    this._stopTyping(user);

    const html = this._generateTypingHTML(user);
    this.peoplesTarget.innerHTML+= html;
  }
      
  _stopTyping(user) {
    const element = this.peoplesTarget.querySelector(`small#user_${user.user_id}`);
    if (element)
      element.remove();
  }

  _receibedMessage(message) {
    const html = String(message.user_id) === this.info.user_id ? message.self_message : message.message;
    this.messagesTarget.insertAdjacentHTML('beforeend', html)
    this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight);
  }

  _generateTypingHTML(user) {
    return `
    <small id="user_${user.user_id}" class="message-typing">
      <strong>${user.user_name}</strong> está digintado...
    </small>`
  }

  sendMessage(event) {
    event.preventDefault();
    if(!this.inputTarget.value) return;

    const message = {
      ...this.info,
      content: this.inputTarget.value,
    };

    this.chat.send(message)
    this.chat.stopTyping(this.info)
    
    this.isTyping = false;
    this.inputTarget.value = '';  
  }

  onChange() {
    const { value } = this.inputTarget;

    if (value && !this.isTyping) {
      this.isTyping = true;
      return this.chat.typing(this.info);
    }
    if (!value && this.isTyping) {
      this.isTyping = false;      
      return this.chat.stopTyping(this.info);
    }
  }
 
}