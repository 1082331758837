import { Controller } from '@hotwired/stimulus'

import { Font } from '../../packs/trix/font'

export default class extends Controller {
  static targets = ['input']
  static values = {
    size: { type: Number, default: 16 }
  }

  onKeyPress(event) {
    if(event.key === 'Enter') {
      event.preventDefault()
      this.sizeValue = this.inputTarget.value || 16;
    }
  }

  change() {
    this.sizeValue = this.inputTarget.value || 16
  }

  increase() {
    ++this.sizeValue;
  }

  decrease() {
    --this.sizeValue;
  }

  sync({ detail: fontSizeString }) {
    this.sizeValue = fontSizeString ? Font.rawNumberFrom(fontSizeString) : 16
  }

  sizeValueChanged() {
    this.inputTarget.value = this.sizeValue
    this.dispatch('change', {
      detail: new Font(this.sizeValue),
    })
  }
}

