import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    metadata: Object,
    data: Object,
    datas: Array,
    filter: Object,
    id: String,
  };
  static targets = ["form", "option"];

  connect() {
    const storaged = localStorage.getItem(this.idValue);

    if (storaged) this.filterValue = JSON.parse(storaged);

    this.optionTargets.forEach((option) => {
      option.value = this.filterValue[option.name] ?? "";
    });
  }

  filterValueChanged() {
    if (!Object.keys(this.filterValue).length)
      return document.dispatchEvent(
        new CustomEvent(`change:${this.identifier}`, {
          detail: {
            original: this.dataValue,
            datas: this.datasValue,
            filter: false,
          },
        })
      );

    localStorage.setItem(this.idValue, JSON.stringify(this.filterValue));

    let { area_id, sector_id } = this.filterValue;
    if (!area_id && !sector_id)
      document.dispatchEvent(
        new CustomEvent(`change:${this.identifier}`, {
          detail: {
            original: this.dataValue,
            datas: this.datasValue,
            filter: false,
          },
        })
      );
    else {
      area_id = area_id.split(",").filter(Boolean);
      sector_id = sector_id.split(",").filter(Boolean);

      const datas = this.datasValue.filter((current) => {
        if (area_id.length && !area_id.includes(String(current.area_id)))
          return false;
        if (sector_id.length && !sector_id.includes(String(current.sector_id)))
          return false;
        return true;
      });

      document.dispatchEvent(
        new CustomEvent(`change:${this.identifier}`, {
          detail: {
            original: this.dataValue,
            datas: datas,
            filter: datas.length !== this.datasValue.length,
          },
        })
      );
    }
  }

  metadataValueChanged() {
    this._sectors = undefined;
    this._areas = undefined;

    if (!this.hasFormTarget) this.insertForm();

    this.formTarget.innerHTML = this.contentForm;
  }

  applyFilter() {
    const filterValue = {};
    this.optionTargets.forEach((option) => {
      filterValue[option.name] = option.value;
    });

    this.filterValue = filterValue;
  }

  insertForm() {
    const form = document.createElement("form");

    form.setAttribute(
      "data-action",
      "change->benchmarking--filter#applyFilter"
    );
    form.setAttribute("data-benchmarking--filter-target", "form");
    form.setAttribute("class", "form-inline justify-content-end");
    this.element.prepend(form);
  }

  get contentForm() {
    return `
      <div class="form-row m-0">
        <select name="area_id" class="form-control form-control-sm" data-benchmarking--filter-target="option">
          <option value="">Setor</option>
          ${this.areas
            .map((area) => `<option value="${area.id}">${area.title}</option>`)
            .join("\n")}
        </select>
        <select name="sector_id" class="form-control form-control-sm" data-benchmarking--filter-target="option">
          <option value="">Filial</option>
          ${this.sectors
            .map(
              (sector) =>
                `<option value="${sector.id}">${sector.title}</option>`
            )
            .join("\n")}
        </select>
      </div>
    `;
  }

  get sectors() {
    if (!this._sectors)
      this._sectors = Object.keys(this.metadataValue?.sectors ?? {})
        .map((key) => ({
          ...this.metadataValue.sectors[key],
          id: String(key),
        }))
        .reduce((acc, cur) => {
          const index = acc.findIndex((a) => a.title === cur.title);

          if (index === -1) return [...acc, { ...cur, id: [cur.id] }];

          acc[index].id.push(cur.id);

          return acc;
        }, []);

    return this._sectors ?? [];
  }

  get areas() {
    if (!this._areas)
      this._areas = Object.keys(this.metadataValue?.areas ?? {}).map((key) => ({
        id: key,
        title: this.metadataValue.areas[key],
      }));

    return this._areas ?? [];
  }
}
