import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "pillar",
    "next",
    "previous",
    "submit",
    "progress",
    "question",
    "errorMessage",
    "input",
    "min",
    "max",
  ];

  initialize() {
    this.index = 0;
    this.backupIndex = 0;
    this.showCurrentPillar();
  }

  next() {
    this.backupIndex = this.index;
    this.index++;
    this.showCurrentPillar();
    this.emitter("change");
  }

  previous() {
    this.backupIndex = this.index;
    this.index--;
    this.showCurrentPillar();
    this.emitter("change");
  }

  goTo(event) {
    const question = event?.detail?.question ?? event?.target ?? event;

    const index = this.pillarTargets.findIndex((element) =>
      element.contains(question)
    );

    if (this.index === index) return;

    this.backupIndex = this.index;
    this.index = index;
    this.showCurrentPillar();
    this.emitter("change");
  }

  showCurrentPillar() {
    const pages = this.pillarTargets.filter((pillar) => {
      const { active } = pillar.dataset;

      if (active) {
        pillar.hidden = active === "false";
        return active !== "false";
      }

      return true;
    });

    if (this.index >= pages.length) this.index = pages.length - 1;
    else if (this.index < 0) this.index = 0;

    window.scrollTo({ top: 0, behavior: "smooth" });

    const maxIndex = this.index === pages.length - 1;

    const progressWidth = (((this.index + 1) / pages.length) * 100).toFixed(0);

    this.progressTargets.forEach((element) => {
      element.setAttribute("style", `width: ${progressWidth}%`);
      element.innerText = `${progressWidth}%`;
    });
    this.nextTargets.forEach((element) => {
      element.hidden = maxIndex;
    });
    this.submitTargets.forEach((element) => {
      element.hidden = !maxIndex;
    });
    this.previousTargets.forEach((element) => {
      element.hidden = !this.index || pages.length <= 1;
    });

    pages.forEach((element, index, array) => {
      element.hidden = index != this.index;
    });
  }

  submit(event) {
    if (this.error()) event.preventDefault(); // Doesn't do anything
  }

  error() {
    let hasError = false;
    const errors = [];

    if (!this.questionTargets.length) return hasError;

    this.questionTargets.forEach((element) => {
      let input = element.querySelector('[data-pagination-target="input"]');

      if (!input) input = element.querySelector('input[type="hidden"]');

      if (!input.hasAttribute("disabled")) {
        const isFilled = Boolean(input.value);

        element.classList.toggle("error", !isFilled);

        if (!isFilled) errors.push(input);

        hasError = hasError || !isFilled;
      }
    });

    this.errorMessageTargets.forEach((element) => {
      element.hidden = !hasError;
      element.innerText = `* É necessário responder todos os campos.\n* Falta responder ${errors.length} questões.`;
    });

    const [first] = errors;

    if (first) {
      const index = this.pillarTargets.findIndex((element) =>
        element.contains(first)
      );

      this.backupIndex = this.index;
      this.index = index;
      this.showCurrentPillar();
      this.emitter("change");
    }

    return hasError;
  }

  rangeInputChangeEventHandler(e) {
    let minBtn = e.target.parentNode.children[0];
    let maxBtn = e.target.parentNode.children[1];
    let minVal = parseInt(minBtn.value);
    let maxVal = parseInt(maxBtn.value);
    let origin = e.target.className;

    if (origin === "min" && minVal > maxVal - 5) {
      $(minBtn).value = maxVal - 5;
    }
    minVal = parseInt(minBtn.value);
    this.minTarget.textContent = this.addSeparator(minVal);

    if (origin === "max" && maxVal - 5 < minVal) {
      maxBtn.value = 5 + minVal;
    }
    maxVal = parseInt(maxBtn.value);
    this.maxTarget.textContent = this.addSeparator(maxVal);
  }

  addSeparator(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  }

  emitter(type, detail = undefined) {
    document.dispatchEvent(new CustomEvent(`${type}:pagination`, { detail }));
  }
}
