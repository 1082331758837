import consumer from "./consumer"

export default class BenchmarkingChannel {
  constructor(benchmarking_id, connected, disconnected, received) {
    this.chat = consumer.subscriptions.create({ channel: 'BenchmarkingChannel', benchmarking_id }, {
      received,

      connected,
      
      disconnected
    })
  }
}