import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'buttonActive', 'inputActive', 'sprint']

  initialize() {
    this.sprintSelected = 0
    this.show()
  }
  
  selected (e) {
    if(this.sprintSelected === e.currentTarget.dataset.id)
      this.sprintSelected = 0
    else
      this.sprintSelected = e.currentTarget.dataset.id
    this.show()
  }

  show() {
    this.inputActiveTarget.setAttribute('value', this.sprintSelected)
    this.buttonActiveTarget.hidden = this.sprintSelected === 0;

    this.sprintTargets.forEach(element => {
      if (element.dataset.id === this.sprintSelected)
        element.classList.add('selected')
      else element.classList.remove('selected')
    });
  }
}