import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'page', 'buttons']

  initialize() {
    this.type = this.element.dataset.home
    this.show();
  }
  
  change(event) {
    if (!this.pageTargets.length) return;
    
    event.preventDefault();
    const { value } = event.target.dataset;
    
    this.type = value;
    this.show();
    this.emitter({ page: value })
  }

  show() {
    this.buttonsTargets.forEach(button => {
      button.classList.toggle('disable', this.type !== button.dataset.value)
    })
    this.pageTargets.forEach(page => {
      page.hidden = this.type !== page.dataset.value;
    })
  }

  emitter(detail = undefined) {
    document.dispatchEvent(
      new CustomEvent("change:sub-page", { detail })
    );
  }
}