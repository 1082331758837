import { Controller } from "@hotwired/stimulus";
import { Calculation } from "../../packs/benchmarking/calculation";

export default class extends Controller {
  static values = { result: Object };

  filterChange({ detail: { datas, original, filter } }) {
    let datasParsed = original;

    if (filter) {
      datasParsed = Calculation.getAhpDiagnostic(datas, original);
    }

    this.resultValue = datasParsed;
  }

  resultValueChanged() {
    if (!this.resultValue.values) return;

    this.element.innerHTML = "";

    this.element.appendChild(this.content);
    console.log('asd')
  }

  get content() {
    const div = document.createElement("div");
    
    div.setAttribute('data-controller', 'chartjs')

    this.page.forEach(page => div.appendChild(page));

    return div;
  }

  get page() {
    return Object.keys(this.resultValue.values).map(key => {
      const div = document.createElement('div');
      const level = this.getLevel(key);
      const label = key.replace(/\[\w+\] /, '')
      const values = this.resultValue.values[key];
      
      div.setAttribute('class', 'card justify-content-center mb-4 p-2 pb-3 col-12')
      div.setAttribute('data-pagination-target', "pillar")

      div.innerHTML = `
        <h5 class="text-center my-4" >${level ?? ''} ${label}</h5>
        <div class="row">
          <div class="col-5 justify-content-center">
            <ul class="list-group" data-controller="heatmap" data-index="global value">
              <li class="list-group-item d-flex justify-content-between align-items-center p-2">
                <b>#</b>
                <p class="m-0 p-0 px-3 col">Nome</p>
                <small>
                  Peso local | Peso global
                </small>
              </li>
              ${values.map((value, index) => `
                <li class="list-group-item d-flex justify-content-between align-items-center p-2">
                  <b>C${index + 1}</b>
                  <p class="m-0 p-0 px-2 col">${value.title ?? ''}</p>
                  <div>
                    <span
                      class="badge badge-pill"
                      data-heatmap-target="item"
                      data-index="value"
                      data-value="${value.value}"
                    >
                      ${value.value}
                    </span>
                    |
                    <span
                      class="badge badge-pill"
                      data-heatmap-target="item"
                      data-value="${value.global_weight}"
                      data-index="global"
                    >
                      ${value.global_weight}
                    </span>
                  </div>
                </li>
              `).join('\n')}
              <br>
              ${values[0].consistency_ratio}
            </ul>
          </div>
          <div class="col align-items-center">
            <div class="chart-container">
              <canvas
                data-values="[${values.map(value => value.value)}]"
                data-labels="[${values.map((_, i) => `&quot;C${i + 1}&quot;`)}]"
                data-chartjs-target="canvas"
                data-use-one-data="true"
              ></canvas>
            </div>
          </div>
        </div>
      `

      return div;
    })
  }

  getLevel(key) {
    let [level] = key.split(' ');

    level = Number(level.replace(/\[Level(\d)Ahp\d+\]/, '$2'));

    if (level <= 2) return '';
    if (level === 3) return 'P'
    if (level === 4) return 'C'
    return 'SC'
  }
}
