import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'canvas', 'color' ]
  
  connect() {
    this.type = this.element.dataset.type;
    this.awaitEvent = Boolean(this.element.dataset.awaitEvent);
    this.ignoreAnimation = Boolean(this.element.dataset.ignoreAnimation);
    this.useCustomColor = Boolean(this.element.dataset.useCustomColor)
    this.dataBackground = [
      'rgba(181, 75, 48, 0.2)',
      'rgba(0, 112, 224, 0.2)',
      'rgba(194, 184, 43, 0.2)',
      'rgba(83, 204, 45, 0.2)',
      'rgba(68, 114, 196, 0.2)',
      'rgba(237, 125, 49, 0.2)',
      'rgba(255, 192, 0, 0.2)',
      'rgba(40, 167, 69, 0.2)',
      'rgba(23, 162, 184, 0.2)',
      'rgba(220, 53, 69, 0.2)',
      'rgba(255, 193, 7, 0.2)',
      'rgba(255, 127, 7, 0.2)',
      'rgba(165, 165, 165, 0.2)',
      'rgba(181, 75, 48, 0.2)',
      'rgba(0, 112, 224, 0.2)',
      'rgba(194, 184, 43, 0.2)',
      'rgba(83, 204, 45, 0.2)',
      'rgba(237, 125, 49, 0.2)',
    ]
    this.dataBorder = [
      'rgba(181, 75, 48, 1)',
      'rgba(0, 112, 224, 1)',
      'rgba(194, 184, 43, 1)',
      'rgba(83, 204, 45, 1)',
      'rgba(68, 114, 196, 1)',
      'rgba(237, 125, 49, 1)',
      'rgba(255, 192, 0, 1)',
      'rgba(40, 167, 69, 1)',
      'rgba(23, 162, 184, 1)',
      'rgba(220, 53, 69, 1)',
      'rgba(255, 193, 7, 1)',
      'rgba(255, 127, 7, 1)',
      'rgba(165, 165, 165, 1)',
      'rgba(181, 75, 48, 1)',
      'rgba(0, 112, 224, 1)',
      'rgba(194, 184, 43, 1)',
      'rgba(83, 204, 45, 1)',
      'rgba(237, 125, 49, 1)',
    ]
    this.multiDataBackground = ['rgba(220, 53, 69, 0.2)', 'rgba(40, 167, 69, 0.2)'];
    this.multiDataBorder = ['rgba(220, 53, 69, 1)', 'rgba(40, 167, 69, 1)'];

    this.defaultOptionsMultiData = {
      aspectRatio: 1,
      legend: {
        display: true,
        labels: {
          fontSize: 15
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      responsive: true,
    }

    if (this.ignoreAnimation) {
      this.defaultOptionsMultiData = {
        ...this.defaultOptionsMultiData,
        animation: false
      }
    }

    if (!this.awaitEvent) 
      this.setup();
  }

  setup() {
    if (this.useCustomColor) this.getColor();
    
    this.canvasTargets.forEach(canvas => {
      const options = this.build(canvas, Boolean(canvas.dataset.useOneData))

      new Chart(canvas, options);
    })
  }

  getScale(data) {
    const max = Math.max(...data)
    const step = max <= 10 ? 5 : 20;

    let scale = max / step;

    return Math.ceil(scale) * step;
  }

  getColor() {
    if (!this.hasColorTarget) return;
    this.dataBorder = this.colorTargets.map(color => colorsHeatmap[color.dataset.color] ?? '#17a2b8');
    this.dataBackground = this.dataBorder;
  }

  build(canvas, useOneData = false) {
    const values = JSON.parse(canvas.dataset.values);

    if (useOneData)
      return this.oneData(canvas);

    if (values[0].length > 2) 
      return this.buildRadarData(canvas)
    
    return this.buildBarData(canvas)
  }

  oneData(ctx) {
    let { type, values, labels } = ctx.dataset;
    values = JSON.parse(values);
    labels = JSON.parse(labels);

    let showLegends = false;

    if (!type) {
      if (values.length === 1)  type = 'bar'
      else if (values.length > 2) type = 'radar'
      else {
        type = 'pie'
        showLegends = true
      }
    }

    let options = {
      ...this.defaultOptionsMultiData,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            return data.labels[tooltipItem.index] + ' : ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          }
        }
      },
      legend: {
        ...this.defaultOptionsMultiData.legend,
        display: showLegends,
      }
    }

    const maxScale = this.getScale(values);

    if (type == 'bar'){
      options = {
        ...options,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              max: maxScale,
            }
          }]
        }
      }
    } else if ( type !== 'pie'){
      options = {
        ...options,
        scale: {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: maxScale,
          },
        }
      }
    }

    if (typeof labels === 'string') {
      labels = labels.split(',');
    }

    const data = {
      labels,
      datasets: [{
        data: values,
        backgroundColor: this.dataBackground,
        borderColor: this.dataBorder,
        borderWidth: 2,
        pointBackgroundColor: '#fff',
        pointBorderColor: '#b84c2f',
        pointBorderWidth: 4,
        pointRadius: 5,
        pointStyle: 'circle',
        pointHoverRadius: 5
      }]
    };

    return {
      type,
      options,
      data,
      responsive: true,
    }
  }

  buildRadarData(ctx) {
    const type = 'radar'
  
    const datasets = []

    let { values, label, labels } = ctx.dataset;
    values = JSON.parse(values)
    label = JSON.parse(label)
    labels = JSON.parse(labels)

    values.forEach((value, index) => {
      datasets.push({
        label: label[index],
        data: value,
        backgroundColor: [ this.multiDataBackground[index] ],
        borderColor: [ this.multiDataBorder[index] ],
        borderWidth: 2,
        pointBackgroundColor: '#fff',
        pointBorderColor: this.multiDataBorder[index],
        pointBorderWidth: 4,
        pointRadius: 5,
        pointStyle: 'circle',
        pointHoverRadius: 5
      })
    })
  
    const scale = this.getScale(
      values.reduce((acc, cur) => [...acc, ...cur], [])
    );
  
    const data = {
      labels,
      datasets
    }

    const options = {
      ...this.defaultOptionsMultiData,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            return data.labels[tooltipItem.index] + ' : ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          }
        }
      },
      scale: {
        pointLabels: {
          fontSize: 10,
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: scale,
        }
      }
    }
  
    return {
      type,
      data,
      options,
      responsive: true
    }
  }

  buildBarData(ctx) {
    const type = 'bar'
  
    const datasets = [];

    let { values, label, labels } = ctx.dataset;
    values = JSON.parse(values)
    label = JSON.parse(label)
    labels = JSON.parse(labels)

    values.forEach((value, index) => {
      datasets.push({
        label: label[index],
        data: value,
        backgroundColor: this.multiDataBackground[index],
        borderColor: this.multiDataBorder[index],
        borderWidth: 2,
      })
    })
    
    const scale = this.getScale(
      values.reduce((acc, cur) => [...acc, ...cur], [])
    );
  
    const data = {
      labels: labels.map(label => label.join(' ')),
      datasets
    }

    const options = {
      ...this.defaultOptionsMultiData,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            return data.labels[tooltipItem.index] + ' : ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          }
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            min: 0,
            max: scale,
          }
        }]
      }
    }
  
    return {
      type,
      data,
      options,
      responsive: true
    }
  }
}