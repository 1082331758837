import Trix from "trix";

class TrixEditor {
  constructor(element) {
    this.element = element;

    if (this.buttonGroupSpacer) {
      this.buttonGroupSpacer?.remove();
      this.originalHeadingButton?.remove();
      this.insertBackgroundColorElements();
      this.insertForegroundColorElements();
      this.insertRemoveColorElements();
      this.insertUnderlineElements();
      this.insertUnderlineColorElements();
      this.insertDividerElements();
      this.insertFontSizeElements();
      this.insertControllers();
    }
  }

  static setupTrix() {
    Trix.config.textAttributes.bold = {
      style: { fontWeight: "bold" },
      parser: (element) =>
        element.style.fontWeight === "bold" || element.style.fontWeight >= 600,
      inheritable: 1,
    };

    Trix.config.textAttributes.italic = {
      style: { fontStyle: "italic" },
      parser: (element) => element.style.fontStyle === "italic",
      inheritable: 1,
    };

    Trix.config.textAttributes.strike = {
      style: { textDecoration: "line-through" },
      parser: (element) => element.style.textDecoration === "line-through",
      inheritable: 1,
    };

    Trix.config.textAttributes.foregroundColor = {
      styleProperty: "color",
      inheritable: 1,
    };

    Trix.config.textAttributes.backgroundColor = {
      styleProperty: "background-color",
      inheritable: 1,
    };

    Trix.config.textAttributes.underline = {
      style: { textDecoration: "underline" },
      parser: (element) => element.style.textDecoration === "underline",
      inheritable: 1,
    };

    Trix.config.textAttributes.underlineColor = {
      styleProperty: "text-decoration-color",
      inheritable: 1,
    };

    Trix.config.textAttributes.fontSize = {
      styleProperty: "font-size",
      inheritable: 1,
    };
  }

  insertBackgroundColorElements() {
    this.buttonGroupTextTools.insertAdjacentHTML(
      "beforeend",
      this.backgroundColorButtonTemplate
    );
    this.dialogsElement.insertAdjacentHTML(
      "beforeend",
      this.dialogBackgroundColorTemplate
    );
  }

  insertForegroundColorElements() {
    this.buttonGroupTextTools.insertAdjacentHTML(
      "beforeend",
      this.foregroundColorTemplate
    );
    this.dialogsElement.insertAdjacentHTML(
      "beforeend",
      this.dialogForegroundColorTemplate
    );
  }

  insertRemoveColorElements() {
    this.buttonGroupTextTools.insertAdjacentHTML(
      "beforeend",
      this.removeColorTemplate
    );
  }

  insertUnderlineElements() {
    //TODO
  }

  insertUnderlineColorElements() {
    //TODO
  }

  insertDividerElements() {
    this.quoteButton.insertAdjacentHTML(
      "afterend",
      this.horizontalButtonTemplate
    );
  }

  insertFontSizeElements() {
    this.buttonGroupTextTools.insertAdjacentHTML(
      "beforeend",
      this.fontSizeButtonTemplate
    );
  }

  insertControllers() {
    this.element.parentElement.setAttribute(
      "data-action",
      `
        trix--font:change->trix--editor#changeSelectionFontSize
        trix--color:change->trix--editor#changeColor
        trix--editor:font:sync->trix--font#sync
      `
    );

    this.element.parentElement.setAttribute(
      "data-controller",
      "trix--editor trix--font trix--color"
    );
  }

  get() {
    return this.toolbarElement.querySelector(
      "[data-trix-button-group=file-tools]"
    );
  }

  get buttonGroupSpacer() {
    return this.toolbarElement.querySelector(".trix-button-group-spacer");
  }

  get buttonGroupBlockTools() {
    return this.toolbarElement.querySelector(
      "[data-trix-button-group=block-tools]"
    );
  }

  get buttonGroupTextTools() {
    return this.toolbarElement.querySelector(
      "[data-trix-button-group=text-tools]"
    );
  }

  get dialogsElement() {
    return this.toolbarElement.querySelector("[data-trix-dialogs]");
  }

  get originalHeadingButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=heading1]");
  }

  get quoteButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=quote]");
  }

  get toolbarElement() {
    return this.element.toolbarElement;
  }

  get backgroundColorButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-font-background" data-trix-action="x-background-color" title="Cor de fundo" tabindex="-1">Cor de fundo</button>';
  }

  get foregroundColorTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-font-color" data-trix-action="x-foreground-color" title="Cor de fonte" tabindex="-1">Cor de fonte</button>';
  }

  get removeColorTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-remove-color" data-action="click->trix--editor#deactivateColor" title="Remover cor" tabindex="-1">Remover cor</button>';
  }

  get horizontalButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-horizontal-rule" data-trix-action="x-horizontal-rule" tabindex="-1" title="Divider">Divider</button>';
  }

  get fontSizeButtonTemplate() {
    return `
      <button type="button" class="trix-button trix-button--icon trix-button--icon-font-size-up" data-action="click->trix--font#increase" title="Aumentar fonte" tabindex="-1">Aumentar fonte</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-font-size-down" data-action="click->trix--font#decrease" title="Diminuir fonte" tabindex="-1">Diminuir fonte</button>
      <input type="number"class="trix-button trix-button--icon-font-size-input"data-trix--editor-target="fontSizeInput"data-trix--font-target="input"data-action="focus->trix--editor#markSelection keydown->trix--font#onKeyPress change->trix--font#change" tabindex="-1">
    `;
  }

  get dialogBackgroundColorTemplate() {
    return `
      <div class="trix-dialog trix-dialog--color" data-trix-dialog="x-background-color" data-trix-dialog-attribute="x-background-color">
        <div class="trix-dialog__link-fields" data-trix--editor-target="backgroundColor">
          <div data-trix--color-target="picker"></div>
        </div>
      </div>
    `;
  }

  get dialogForegroundColorTemplate() {
    return `
      <div class="trix-dialog trix-dialog--color" data-trix-dialog="x-foreground-color" data-trix-dialog-attribute="x-foreground-color">
        <div class="trix-dialog__link-fields" data-trix--editor-target="textColor">
          <div data-trix--color-target="picker"></div>
        </div>
      </div>
    `;
  }
}

TrixEditor.setupTrix();
addEventListener(
  "trix-before-initialize",
  (event) => new TrixEditor(event.target)
);
