// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer, logger } from "@rails/actioncable"

const protocol = () => {
  return Boolean(window.location.protocol.match('https')) ? 'wss' : 'ws'
}

const host = () => {
  return window.location.host
}

const path = () => {
  return '/cable'
}

const isBeta = (host) => {
  return Boolean(window.location.host.match(/beta|:3000/))
}

function config() {
  logger.enabled = isBeta();
}

function getWebSocketURL() {
  config();
  return `${protocol()}://${host()}${path()}`
}


export default createConsumer(getWebSocketURL)