import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'page', 'buttons']

  initialize() {
    this.index = 0;
  }
  
  next(event) {
    if(this.index + 1 < this.pageTargets.length){
      this.index++;
      this.show();
    }
  }

  previus(event) {
    if(this.index - 1 >= 0){
      this.index--;
      this.show();
    }
  }

  show() {
    this.pageTargets.forEach((page, index) => {
      page.querySelectorAll('canvas').forEach(element => element.hidden = this.index !== index);
    })
  }
}