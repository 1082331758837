import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['input', 'value', 'button']

  connect() {
    if (this.hasButtonTarget)
      this.block = this.buttonTarget.dataset.block === 'true'
  }

  submit(e) {
    if(this.block)
      return;
    e.preventDefault();

    const search = this.inputTarget.value.toLowerCase();

    this.show(search)
  }

  clear(e){
   if (!e.inputType){
      if (this.block)
        window.location = window.location.href.split("?")[0];
      else
        this.show('')
    }
  }

  show(search) {
    this.valueTargets.forEach(element => {
      const lowValue = element.dataset.search.toLowerCase();
      element.hidden = !lowValue.includes(search)
    });
  }

}
