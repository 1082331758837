import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ 'remove', 'add', 'input', 'peopleinvolved' ]

  connect() {
    this.removeInput();
  }

  removeInput() {
    if(this.removeTarget.checked){
      this.inputTargets.forEach(input => {
        input.hidden = true
        this.peopleinvolvedTargets.forEach(input => {
          input.required = false
        })
      })
    }
  }

  addInput() {
    if(this.addTarget.checked){
      this.inputTargets.forEach(input => {
        input.hidden = false
        this.peopleinvolvedTargets.forEach(input => {
          input.required = true
        })
      })
    }
  }
}