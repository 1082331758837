import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['detail']

  connect() {
    this.collapseDetailsWidget()
  }

  collapseDetailsWidget() {
    this.detailTarget.hidden = !this.detailTarget.hidden;
  }
}