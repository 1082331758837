import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'form', 'button' ]

  connect() {
    const { dataset } = this.element
    this.isModal = dataset.type === 'modal'
    this.id = dataset.id
    this.modal = document.querySelector(dataset.target)
  } 
  
  openModal(event) {
    this.modal.querySelector('.modal-body').innerHTML = this.formTarget.innerHTML;
  }

  closeModal(event) {
    if (!this.isModal) return;

    const values = {};
    this.element.querySelectorAll('input').forEach(input => {
      values[input.name] = input.value;
    })

    this.notify('closeModal', { id: this.id, values })
    $(this.modal).modal('hide');
    this.element.remove();
  }

  update(event) {
    const { id, values } = event.detail;

    if (this.id !== id) return;

    this.element.querySelectorAll('input').forEach(input => {
      input.setAttribute('value', values[input.name] || '');
    })

    this.isFilled();
    this.notify('change');
  }

  isFilled() {
    let filled = true;

    this.element.querySelectorAll('input').forEach(input => {
      if (input.name.includes('evaluation_result'))
        filled = Boolean(filled && input.value);
    })

    this.buttonTarget.classList.toggle('btn-outline-success', filled)
    this.buttonTarget.classList.toggle('btn-outline-dark', !filled)
  }

  notify(type, detail = undefined) {
    document.dispatchEvent(
      new CustomEvent(`${type}:evaluation`, { detail })
    );
  }
}