import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['zip', 'neighborhood', 'city', 'state', 'country', 'error']

  connect() {
    if (this.hasErrorTarget) {
      this.element.setAttribute('novalidate', 'novalidate');
      this.useCustomValidate = true;
    }
  }

  async changeZip() {
    try {
      if (this.zipTarget.value.length !== 9) return;

      const url = `https://viacep.com.br/ws/${this.zipTarget.value}/json/`

      const response = await fetch(url);
      const data = await response.json();

      if(data?.erro) return;

      this.neighborhoodTarget.value = data.bairro;
      this.cityTarget.value = data.localidade;
      this.stateTarget.value = data.uf;
      this.countryTarget.value = 'Brasil';

    } catch (e){
      // TODO
    }
  }

  validate(event) {
    if (!this.useCustomValidate) return;

    const schema = {};

    this.element.
      querySelectorAll('input[required="required"]')
      .forEach(input => {

        const label = this.element.querySelector(`label[for="${input.id}"]`);

        schema[input.name] = {
          value: input.value,
          name: label?.innerHTML ?? input?.dataset?.name ?? input?.name, 
        }
      })

    const errors = [];

    Object.keys(schema).forEach(key => {
      const data = schema[key];

      if (!data.value)
        errors.push(data.name)
    })

    const ul = this.errorTarget.querySelector('ul');
    if (errors.length) {
      event.preventDefault();
      this.errorTarget.hidden = false;
      ul.innerHTML = this._generateListErrors(errors);
    } else {
      this.errorTarget.hidden = true;
      ul.innerHTML = '';
    }
  }

  _generateListErrors(errors) {
    return `<ul>
      ${errors.map(error => `<li>${error}</li>`).join('\n')}
    </ul>`;
  }
}