import { Controller } from "@hotwired/stimulus"
import BenchmarkingChannel from '../../channels/benchmarking_channel'

export default class extends Controller {

  static targets = [ 'status', 'percentage', 'result' ]

  connect() {
    this.id = Number(this.element.dataset.id);
    this.websocket = new BenchmarkingChannel(
      this.id,
      this._connected.bind(this),
      this._disconnected.bind(this),
      this._received.bind(this),
    )
  }

  _connected(data) {
    // console.log({ message: '_connected', data })
  }

  _disconnected(data) {
    // console.log({ message: '_disconnected', data })
  }

  _received({ type, status, id, percentage = undefined }) {
    if (type === 'benchmarking')
      this._updateBenchmarking({ status, percentage })
    else
      this._updateBenchmarkingResult({ status, id, percentage })
  }

  _updateBenchmarking({ status, percentage }) {
    if (this.hasPercentageTarget)
      this.percentageTarget.innerText = this._formatPercentage(percentage);

    if (this.hasStatusTarget)
      this.statusTarget.innerText = this._translateStatus(status);
  }

  _updateBenchmarkingResult({ status, id }) {
    const result = this.resultTargets.find(element => Number(element.dataset.id) === id);

    if (!result) return;
    
    result.innerText = this._translateStatus(status);
  }

  _formatPercentage(percentage) {
    return `${parseFloat(percentage).toFixed(2)}%`
  }

  _translateStatus(status) {
    if (status === 1) return 'Em processamento';

    if (status === 2) return 'Finalizado';

    if (status === -2) return 'Sem resultados';

    if (status === -3) return 'Tipo não suportado';

    return 'Erro'
  }
}