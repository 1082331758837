import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    get(event) {
        event.preventDefault();
        let modalBody = document.getElementById('modalBody');
        let modalTitle = document.getElementById('modalTitle');

        fetch(event.target.dataset.url)
            .then(response => response.text())
            .then(html => {
                modalBody.innerHTML = html;
                modalTitle.innerHTML = event.target.dataset.header;
            })
    }
}
