import { Controller } from "@hotwired/stimulus"
import * as d3 from 'd3'

export default class extends Controller {

  static targets = [ 'label', 'color' ]

  setup() {
    const element = this.element.querySelector(this.element.dataset.id)

    if(Boolean(element.innerHTML))
      return;

    const dataTopsis = this.labelTargets.map(label => {
      const { id, name, value } = label.dataset;
      return {
        id,
        name,
        value
      }
    })

    this.generateGraph(dataTopsis)
  }

  generateGraph(dataTopsis) {
    const width = this.element.offsetWidth * 0.9;
    const height = 980;
    const outerRadius = Math.min(width, height) / 2;
    let innerRadius
    if(screen.width < 1000){
      innerRadius = 90;
    } else {
      innerRadius = 180;
    }

    const xAxis = g => g
        .attr("text-anchor", "middle")
        .call(g => g.selectAll("g")
            .data(dataTopsis)
            .join("g")
            .attr("transform", d => `
          rotate(${((x(d.name) + x.bandwidth() / 2) * 180 / Math.PI - 90)})
          translate(${innerRadius},0)
        `)
            .call(g => g.append("line")
                .attr("x2", -5)
                .attr("stroke", "#000"))
            .call(g => g.append("text")
                .attr("transform", d => (x(d.name) + x.bandwidth() / 2 + Math.PI / 2) % (2 * Math.PI) < Math.PI
                    ? "rotate(90)translate(0,16)"
                    : "rotate(-90)translate(0,-9)")
                .text(d => d.name)
                .style('font-size', '10px')))


    const y = d3.scaleRadial()
        .domain([0, d3.max(dataTopsis, d => d.value)])
        .range([innerRadius, outerRadius]);

    const x = d3.scaleBand()
        .domain(dataTopsis.map(d => d.name))
        .range([0, 2 * Math.PI])
        .align(0);

    const arc = d3.arc()
        .innerRadius(d => y(d[0]))
        .outerRadius(d => y(d[1]))
        .startAngle(d => x(d.data.name))
        .endAngle(d => x(d.data.name) + x.bandwidth())
        .padAngle(0.01)
        .padRadius(innerRadius);

    const svg = d3.select(this.element.dataset.id)
        .append('svg')
        .attr("viewBox", `${-width / 2} ${-height / 2} ${width} ${height}`)
        .style("width", "100%")
        .style("height", "auto")
        .style("font", "10px sans-serif");


    svg.append("g")
        .attr('data-info', 'values')
        .selectAll("g")
        .data(d3.stack().keys(['value'])(dataTopsis))
        .join("g")
        .selectAll("path")
        .data(d => d)
        .join("path")
        .attr("fill", d => this.getColor(d.data.id))
        .attr("title", d => d.data.name)
        .style('cursor', 'pointer')
        .on('click', event => this.onClick(event.target.__data__.data.id))
        .on('mouseover', event => this.onHover(event.target.__data__.data.id))
        .on('mouseleave', event => this.onLeave(event.target.__data__.data.id))
        .attr("d", arc);

    svg.append("g")
        .attr('data-info', 'names')
        .call(xAxis);

    svg.append("g")
        .attr('data-info', 'scaleY')
        .attr("text-anchor", "middle")
        .call(g => g.selectAll("g")
            .data(y.ticks(5).slice(1))
            .join("g")
            .attr("fill", "none")
            .call(g => g.append("circle")
                .attr("stroke", "#000")
                .attr("stroke-opacity", 0.5)
                .attr("r", y))
            .call(g => g.append("text")
                .attr("y", d => -y(d))
                .attr("dy", "0.35em")
                .attr("stroke", "#fff")
                .attr("stroke-width", 5)
                .text(y.tickFormat(5, "%"))
                .clone(true)
                .attr("fill", "#000")
                .attr("stroke", "none")));

    return svg.node();
  }

  getColor(id) {
    const color = this.colorTargets.find(element => element.dataset.id === id)
    return colorsHeatmap[color.dataset.color] ?? '#17a2b8';
  }

  onClick(id) {
    if (this.timeout) {
      this.removeClass();
      clearTimeout(this.timeout);
    }
    this.labelClicked = this.labelTargets.find(label => label.dataset.id == id);
    this.labelClicked.classList.add('clicked')
    this.labelClicked.querySelector('.anchor').scrollIntoView();
    this.timeout = setTimeout(() => this.removeClass(), 3000)
  }

  removeClass(className = 'clicked') {
    this.labelClicked.classList.remove(className)
  }

  onHover(id) {
    const labelClicked = this.labelTargets.find(label => label.dataset.id === id);
    labelClicked.classList.add('hover')
  }

  onLeave(id) {
    const labelClicked = this.labelTargets.find(label => label.dataset.id === id);
    labelClicked.classList.remove('hover')
  }
}