import { Controller } from "@hotwired/stimulus"
let select
export default class extends Controller {
    static targets = ["select"]
    connect() {
        select = this.selectTarget
    }
    get(e) {       
        let modal_id = e.currentTarget.getAttribute("modal_id")
        let header = e.currentTarget.getAttribute("header")
        let baseurl = window.location.href
        let url = baseurl.split('indicators')[0] + e.currentTarget.getAttribute("url") 
        if(url.indexOf('edit')!= -1){
            if(this.selectTarget.value == "") return
            url = url.split('edit')[0] + this.selectTarget.value + "/edit"
        }     
        fetch(url)
            .then(response => response.text())
            .then(html => {
                document.getElementById(modal_id).getElementsByClassName('modal-title')[0].innerHTML = header
                document.getElementById(modal_id).getElementsByClassName('modal-body')[0].innerHTML = html
            }).then(() => $("#"+modal_id).modal('show'))
    }
}
