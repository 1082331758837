import { Controller } from '@hotwired/stimulus'
import Trix from 'trix';

export default class extends Controller {
  static values = {
    defaultColor: {type: String, default: '#000000'},
    color: {type: String, default: '#000000'},
  }

  static classes = ['active', 'inactive', 'linkError']
  static targets = ['backgroundColor', 'textColor', 'underlineColorPicker', 'underlineColorPickerModal', 'fontSizeControls', 'fontSizeInput']

  initialize() {
    this.allowSync = true
    this.trix = this.element.querySelector('trix-editor');
    this.trix.setAttribute('data-action', 'keydown->trix--editor#sync keyup->trix--editor#sync')
    addEventListener('trix-action-invoke', this.insertHorizontalRule.bind(this))
  }

  changeColor({ detail }) {
    this.colorValue = detail.color;
    const element = detail.element;

    if(this.backgroundColorTarget.contains(element)) {
      this.trixEditor.activateAttribute('backgroundColor', this.colorValue)
    } else if(this.textColorTarget.contains(element)) {
      this.trixEditor.activateAttribute('foregroundColor', this.colorValue)
    }
    // } else {
    //   this.trixEditor.activateAttribute('underlineColor', this.colorValue)
    // }
  }

  connect() {
    this.element.classList.add(...this.inactiveClasses)
    this.element.classList.remove(...this.activeClasses)
  }

  markSelection() {
    this.trixEditor.activateAttribute('frozen')
    this.fontSizeInputTarget.focus()
    this.trix.blur()
  }

  changeSelectionFontSize({ detail: font }) {
    this.trixEditor.activateAttribute('fontSize', font.px)
    this.trixEditor.deactivateAttribute('frozen')
  }

  deactivateColor() {
    this.trixEditor.deactivateAttribute('backgroundColor')
    this.trixEditor.deactivateAttribute('foregroundColor')
  }

  toggleUnderline() {
    if(this.trixEditor.attributeIsActive('underline')) {
      this.trixEditor.deactivateAttribute('underline')
    } else {
      this.trixEditor.activateAttribute('underline')
    }

    this.trix.focus()
  }

  sync() {
    // if(this.trixEditor.attributeIsActive('underline')) {
    //   this.underlineColorPickerTarget.disabled = false
    //   this.underlineColorPickerTarget.classList.remove('text-gray-300')
    // } else {
    //   this.underlineColorPickerTarget.disabled = true
    //   this.underlineColorPickerTarget.classList.add('text-gray-300')
    // }

    if (this.pieceAtCursor.attributes.has('fontSize')) {
      this.dispatch('font:sync', {
        detail: this.pieceAtCursor.getAttribute('fontSize') ?? 0
      })
    }
  }

  toggleUnderlineColorPicker() {
    const piece = this.pieceAtCursor

    if (piece.attributes.has('underline')) {
      const indexOfPiece = this.trixEditorDocument.toString().indexOf(piece.toString())
      const textRange = [indexOfPiece, indexOfPiece + piece.length]
      this.trixEditor.setSelectedRange(textRange)
    }

    this.underlineColorPickerModalTarget.classList.toggle('hidden')
  }

  insertHorizontalRule(event) {
    const { target } = event;

    if (event.actionName == "x-horizontal-rule")
      target.editor.insertAttachment(
        new Trix.Attachment({ content: '<hr>', contentType: 'vnd.rubyonrails.horizontal-rule.html' })
      )
  }

  get pieceAtCursor() {
    return this.trixEditorDocument.getPieceAtPosition(this.trixEditor.getPosition())
  }

  get trixEditor() {
    return this.trix.editor
  }

  get trixEditorDocument() {
    return this.trix.editor.getDocument()
  }

  get fontSizeDropdownLabelContainer() {
    return this.element.querySelector('[data-custom-dropdown-target="placeholderText"]')
  }

  get currentState() {
    return {
      boldValue: this.boldValue,
      italicValue: this.italicValue,
      alignmentValue: this.alignmentInputTarget.value,
      color: this.colorValue,
      sizeValue: this.sizeValue,
      trix: this.trix
    }
  }
}