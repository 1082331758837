import { Controller } from "@hotwired/stimulus"
import { Notify } from '../packs/notify'

export default class extends Controller {

  static targets = [ 'input', 'button' ]

  connect() {
    this.url = this.element.dataset.url
    this.notify = new Notify(this.identifier)
  }

  async onChange(event) {
    const { value } = event.target;

    if (!value) return;

    try {
      const response = await fetch(`${this.url}?id=${value}`)

      const data = await response.json();

      this.values = this._generateOptions(data);

      setTimeout(() => this._updateInputs(), 100)
    } catch {
      this.notify.notify({
        title: 'Erro ao pesquisar as avaliações',
        message: ''
      })
    }
  }

  add() {
    setTimeout(() => this._addInput(), 100)
  }

  _updateInputs() {
    this.inputTargets.forEach(input => input.innerHTML = this.values)
  }

  _addInput() {
    if (!this.values) return;

    const input = this.inputTargets[this.inputTargets.length - 1];
    input.innerHTML = this.values;
  }

  _generateOptions(values = []) {
    return values.map(value => `
      <option value="${value.id}">${value.label}</option>
    `).reduce(
        (acc, cur) => `${acc}\n${cur}`,
        '<option>Por favor selecione</option>'
      )
  }
}