import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'item', 'distance', 'asIs', 'toBe']

  initialize() {
    let indexs = [ undefined ];
    if (this.element.dataset.index) {
      const indexsDataset = this.element.dataset.index.split(' ');
      indexs = [...indexs, ...indexsDataset]
    } 

    indexs.forEach(index => {
      const items = this.itemTargets.filter(item => item.dataset.index === index);
      this.setup(items);
    })

    this.effort();
    document.dispatchEvent(new CustomEvent(`setup:heatmap`));
  }
  
  setup(items) {
    items.forEach(element => {
      const value = this.getValue(element.dataset);

      if(typeof this.max === 'undefined' || value > this.max)
        this.max = value;

      if(typeof this.min === 'undefined' || value < this.min)
        this.min = value;
    });

    this.scale = (this.max - this.min);
    items.forEach(element => {
      const className = this.classFoHeatmap(this.getValue(element.dataset));
      element.classList.add(className);
      element.setAttribute('data-color', className)
    });

    
  }

  effort() {
    if (this.hasAsIsTarget) {
      const values = [];

      this.asIsTargets.forEach((element, index) => {
        const asIs = this.getValue(element.dataset); 
        const toBe = this.getValue(this.toBeTargets[index].dataset);

        values.push(toBe - asIs);
      });

      this.distanceTarget.innerHTML += (
        values
          .map(value => this.generateHTML(value))
          .join('')
      )

      this.distanceTarget.innerHTML += this.addGraph(values, this.distanceTarget.dataset.id)
      this.generateGraphEffort(values);
    }
  }

  generateGraphEffort(values) {
    const ctx = this.distanceTarget.getElementsByTagName('canvas');
    const backgroundColor = values.map(value => {
      const distance = this.getDistance(value);
      return  colorsEffort[distance[1]];
    });
    new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: values.map((_, index) => `A${index + 1}`),
        datasets: [{
          data: values.map(value => Math.abs(value).toFixed(2)),
          backgroundColor,
        }]
      },
      options: {
        aspectRatio: 1,
        tooltips: {
          enabled: true,
          intersect: true,
          displayColors: false,
          bodyFontSize: 16,
          bodyFontStyle: 'bold',
        },
        legend: {
          display: false,
        },
        responsive: false,
        layout: {
          padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 20
          }
        },
      }
    });
  }

  addGraph(values, id) {
    const labels = values.map((_, index) => `A${index + 1}`)
    return `
      <div class="modal fade" id="effort-${id}" tabindex="-1" role="dialog" aria-labelledby="effort-${id}" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="effort-${id}-label">Esforço</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body d-flex align-items-center">
              <ul class="list-group col p-0">
                <li class="list-group-item flex-column align-items-start p-2">
                  <h5 class="m-0 text-center">Esforço</h5>
                </li>
                ${values.map((value, index) => this.generateHTML(value, true, index)).join('')}
              </ul>
              <div class="col">
                <canvas data-values="${JSON.stringify(values)}" data-labels="${(labels)}"></canvas>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
            </div>
          </div>
        </div>
      </div>
    `
  }

  getValue(dataset) {
    return Number(dataset.value.replace('%', '').replace(',', '.'));
  }

  generateHTML(value, showAttribute = false, index = 0) {
    const distance = this.getDistance(value);
    return `
    <li
      class="list-group-item d-flex justify-content-between align-items-center p-2"
      ${showAttribute ? `title="${this.asIsTargets[index].dataset.name}"` : ''}
    >
      ${showAttribute ? `<b>A${index + 1}</b>` : ''} ${distance[0]}
      <span class="badge badge-pill ${distance[1]}">
        ${value <= 0 ? '--- ' : Math.abs(value).toFixed(2)}%
      </span>
    </li>`;
  }

  getDistance(value) {
    if(value <= 0)
      return [ 'Manter Esforço', 'badge-info' ];
    if (value <= 5)
      return [ 'Esforço Mínimo', 'badge-success' ];
    if (value <= 15)
      return [ 'Esforço Mediano', 'badge-warning' ];
    return [ 'Esforço Elevado', 'badge-danger' ];
  }

  classFoHeatmap(value){
    const valueInScale = ((value - this.min) / this.scale) * 100

    if(this.scale === 0 || valueInScale > 80)
      return 'badge-success';
    if (valueInScale <= 20)
      return 'badge-danger';
    if (valueInScale <= 40)
      return 'badge-warning-alt';
    if (valueInScale <= 60)
      return 'badge-warning';
    if (valueInScale <= 80)
      return 'badge-success-alt';
  }
}
