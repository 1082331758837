import { Controller } from "@hotwired/stimulus"
import { schemeCategory10, schemeSet3, schemePaired } from 'd3'

export default class extends Controller {

  static targets = [ 'graph' ]

  initialize() {
    this.colors = [
      ...schemeCategory10,
      ...schemeSet3,
      ...schemePaired,
    ]

    this.graphTargets.forEach(ctx => {
      const data = this.buildData(ctx);
      const options = this.buildOptions(ctx);

      new Chart(ctx, {
        type: 'line',
        options,
        data,
      });
    })
  }

  buildData(ctx) {
    const datasets = []

    let { values, valueNames, labels } = ctx.dataset;
    values = JSON.parse(values)
    valueNames = JSON.parse(valueNames)
    labels = JSON.parse(labels)

    values.forEach((value, index) => {
      datasets.push({
        label: valueNames[index],
        data: value,
        backgroundColor: undefined,
        borderColor: this.colors[index],
      })
    })
  
    return {
      labels,
      datasets
    }
  }

  buildOptions(ctx) {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: ctx.dataset.chartName,
        }
      }
    }
  }
}