import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['list', 'input', 'value']

  connect() {
    this.url = this.element.dataset.url;
    if (this.valueTarget.value) {
      this.enableInput();
      this.fetchData(this.valueTarget.value)
    } else {
      this.disabledInput();
    }
  }

  onChange(event) {
    const {target} = event;
    const {value} = target;

    if (!value) {
      this.disabledInput();
      return;
    } else {
      this.enableInput();
    }
    this.fetchData(value);
  }

  enableInput() {
    this.inputTarget.disabled = false
  }

  disabledInput() {
    this.inputTarget.disabled = true
  }

  async fetchData(id) {
    try {
      const url = this.url.replace('sector-id', id)
      const response = await fetch(url);
      const users = await response.json();
      this.insertOptions(users);
    } catch (err) {
      this.disabledInput();
    }
  }

  insertOptions(users) {
    this.listTarget.innerHTML = ``;
    users.forEach(user => {
      const li = document.createElement('li');
      li.setAttribute('data-id', user.id)
      li.setAttribute('data-name', user.full_name)
      li.setAttribute('data-auto-complete-selection-target', 'option')
      li.setAttribute('data-action', 'click->auto-complete-selection#onClick')
      li.innerText = user.full_name

      this.listTarget.appendChild(li)
    })

  }
}