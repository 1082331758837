import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'body', 'info' ]
  
  connect() {
    const url = `${window.location.pathname}.html?partial=true`;
    this.toast = document.getElementById('toast')
    this.fetchEvaluation(url)
    this.hiddenColumnsEmpty();
  }

  async fetchEvaluation(url) {
    if (!this.hasBodyTarget || !this.hasInfoTarget) return;
    
    const response = await fetch(url);

    this.toast.querySelector('.toast-title').innerText = 'Atualizando avaliação';
    this.toast.querySelector('.toast-body').innerText = 'Inserindo cópia da avaliação respondida';
    $(toast).toast('show')

    const data = await response.text();

    this.bodyTarget.innerHTML = data;

    document.querySelector('.evaluation[data-pagination-target="next"]').click();

    this.infoTarget.hidden = true;
    this.bodyTarget.hidden = false;
  }

  hiddenColumnsEmpty() {
    document.querySelectorAll('table').forEach(table => {
      if (table.dataset.ignore) return;

      const ths = table.querySelectorAll('thead th');

      if (!ths.length) return;

      ths.forEach((th, index) => {
        const rows = Array.from(
          table.querySelectorAll(`tbody tr > :nth-child(${index + 1})`)
        )

        const isFilled = rows.reduce((acc, cur) => {
          const text = cur.innerText;

          return acc || Boolean(text)
        }, false)

        if (isFilled) return;

        th.hidden = true;

        rows.forEach(row => row.hidden = true)
      })
    })
  }
}